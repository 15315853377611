import React from 'react';
import Box from '@mui/material/Box';
import close from '../../images/close-icon.svg';
import Modal from '@mui/material/Modal';
import SecurityAnswer from './securityAnswer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '350px',
  bgcolor: '#ffffff',
  p: 1,
  borderRadius: '10px'
};

const margin = {
  m: 2,
};

function SecurityAnswerModal({ open, handleClose, onSubmit }) {
  return (
    <div className=''>
      <Modal
        sx={margin}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className=''>
            <div className='modalHeaderBg h-24 rounded-[5px] border p-2 grid justify-items-end'>
              <img src={close} alt='' className=' cursor-pointer' onClick={handleClose} />
            </div>
          </div>
          <SecurityAnswer onSubmit={onSubmit} />
        </Box>
      </Modal>
    </div>
  );
}

export default SecurityAnswerModal;
