import React from "react";
import { useField, useFormikContext } from "formik";
import Multiselect from "multiselect-react-dropdown";
import TextError from "./TextError";
import "../../index.css";

const RemitaFormikControl = (props) => {
  const { control, label, name, options, ...rest } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (selectedList) => {
    const selectedValues = selectedList ? selectedList.map((option) => option.value) : [];
    setFieldValue(name, selectedValues.join(",")); 
  };

  const mappedOptions = options
    ? options.map((option) => ({
        value: option.VALUE,
        name: option.DISPLAY_NAME,
      }))
    : [];

  const selectedValues = Array.isArray(field.value)
    ? field.value.split(",").map((val) => {
        const option = options.find((opt) => opt.VALUE === val);
        return option ? { value: option.VALUE, name: option.DISPLAY_NAME } : null;
      }).filter(Boolean) // Filter out null values
    : [];

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const renderInput = () => {
    switch (control) {
      case "input":
        return (
          <input
            id={name}
            className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            {...field}
            {...rest}
          />
        );
      case "textarea":
        return (
          <textarea
            id={name}
            className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            {...field}
            {...rest}
          />
        );
      case "select":
        return (
          <select
            id={name}
            className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            {...field}
            {...rest}
          >
            <option value="">Select an option</option>
            {options.map((option) => (
              <option key={option.VALUE} value={option.VALUE}>
                {option.DISPLAY_NAME}
              </option>
            ))}
          </select>
        );
      case "multiselect":
        return (
          <Multiselect
            options={mappedOptions}
            selectedValues={selectedValues}
            onSelect={handleChange}
            onRemove={handleChange}
            displayValue="name"
            placeholder="Select options"
            onBlur={handleBlur}
            className="custom-multiselect mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            {...rest}
          />
        );
      case "checkbox":
        return options.map((option) => (
          <div key={option.VALUE} className="mb-1 flex items-center">
            <input
              type="checkbox"
              id={`${name}-${option.VALUE}`}
              value={option.VALUE}
              checked={field.value.includes(option.VALUE)}
              onChange={(e) => {
                const set = new Set(field.value.split(","));
                if (e.target.checked) {
                  set.add(option.VALUE);
                } else {
                  set.delete(option.VALUE);
                }
                setFieldValue(name, Array.from(set).join(",")); // Store as comma-separated string
              }}
              className="mr-2"
              {...rest}
            />
            <label htmlFor={`${name}-${option.VALUE}`}>{option.DISPLAY_NAME}</label>
          </div>
        ));
      case "date":
        return (
          <input
            type="date"
            id={name}
            className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            {...field}
            {...rest}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="mb-3">
      <label className="text-pri-text block" htmlFor={name}>
        {label}
      </label>
      {renderInput()}
      {meta.touched && meta.error && (
        <div className="text-red-600 text-sm mt-1">
          <TextError>{meta.error}</TextError>
        </div>
      )}
    </div>
  );
};

export default RemitaFormikControl;
