import { React, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { generatePass } from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import { showSuccessToast } from "../../utils/api-utils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { format } from "date-fns";
import Loading from "../SelectValue/Loading";

function GenerateGatePassForm(props) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const [sendMessage, setSendMessage] = useState(false);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const [selectedArrivalDateTime, setSelectedArrivalDateTime] = useState(
    new Date()
  );
  const [selectedDepartureDateTime, setSelectedDepartureDateTime] = useState(
    new Date()
  );

  const handleArrivalDateTimeChange = (newDateTime) => {
    setSelectedArrivalDateTime(newDateTime);
  };

  const handleDepartureDateTimeChange = (newDateTime) => {
    setSelectedDepartureDateTime(newDateTime);
  };

  const genderOptions = [
    { key: "Select an option", value: "" },
    { key: "male", value: "Male" },
    { key: "female", value: "Female" },
  ];

  const entryMode = [
    { key: "Select an option", value: "" },
    { key: "driving", value: "driving" },
    { key: "walk-In", value: "walk-in" },
  ];

  const direction = [
    { key: "Select an option", value: "" },
    { key: "In/Out", value: "IO" },
    { key: "Out", value: "O" },
  ];

  const initialValues = {
    visitors_number: "",
    entry_mode: "",
    eventDateTime: new Date(),
    visitors_name: "",
    gender: "",
    direction: "",
    address: "",
  };

  const validationSchema = Yup.object({});

  const onSubmit = async (values, { resetForm }) => {
    const {
      visitors_number,
      entry_mode,
      gender,
      direction,
      address,
      plate_number,
      referralPhoneNumber,
      visitors_name,
      // visitors_count,
    } = values;

    const arrivalDate = selectedArrivalDateTime.$d;
    const formattedArrivalDate = format(arrivalDate, "yyyy-MM-dd HH:mm:ss");
    const departureDate = selectedDepartureDateTime.$d;
    const formattedDepartureDate = format(departureDate, "yyyy-MM-dd HH:mm:ss");

    if (entry_mode === "walk-in") {
      const formData = {
        gender: gender,
        access_mode: direction,
        estate_code: props.dataReceived,
        name: visitors_name,
        address: address,
        entering_mode: entry_mode,
        phonenumber: phoneNumber,
        expected_arrival_time: formattedArrivalDate,
        expected_departure_time: formattedDepartureDate,
        send_message: sendMessage,
        number_plate: "",
        visitor_count: "",
        estate_sector: "",
      };
      const { payload } =
        formData.security_answer !== null || undefined
          ? await dispatch(generatePass(formData))
          : "";
      if (payload.status_code === "0") {
        showSuccessToast(payload.message);
      }
    } else {
      const formData = {
        gender: gender,
        access_mode: direction,
        estate_code: props.dataReceived,
        name: visitors_name,
        address: address,
        entering_mode: entry_mode,
        phonenumber: visitors_number,
        expected_arrival_time: formattedArrivalDate,
        expected_departure_time: formattedDepartureDate,
        send_message: sendMessage,
        number_plate: plate_number,
        visitor_count: values.visitors_count,
        estate_sector: "",
      };
      const { payload } =
        formData.security_answer !== null || undefined
          ? await dispatch(generatePass(formData))
          : "";
      if (payload.status_code === "0") {
        showSuccessToast(payload.message);
      }
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.visitors_name}
                type="text"
                label="Visitors Name:"
                name="visitors_name" // Use a unique name here
              />

              <FormikControl
                control="input"
                value={values.visitors_number}
                type="text"
                label="Visitor's Number:"
                name="visitors_number"
              />

              <FormikControl
                control="input"
                value={values.address}
                type="text"
                label="Visitor's Address:"
                name="address"
              />

              <FormikControl
                control="select"
                options={genderOptions}
                label="Gender"
                name="gender" // Use a unique name here
                values={values.gender}
                onChange={handleChange}
                error={touched.gender && errors.gender}
              />
              <FormikControl
                control="select"
                options={direction}
                label="Direction"
                name="direction"
                values={values.direction}
                onChange={handleChange}
                error={touched.direction && errors.direction}
              />

              <FormikControl
                control="select"
                options={entryMode}
                label="Entry Mode"
                name="entry_mode"
                values={values.entry_mode}
                onChange={handleChange}
                error={touched.entry_mode && errors.entry_mode}
              />
              {values.entry_mode === "driving" && (
                <FormikControl
                  control="input"
                  type="text"
                  label="Plate Number"
                  name="plate_number"
                  values={values.referralPhoneNumber}
                  onChange={handleChange}
                  // error={
                  //   touched.referralPhoneNumber && errors.referralPhoneNumber
                  // }
                />
              )}
              {values.entry_mode === "driving" && (
                <FormikControl
                  control="input"
                  type="text"
                  label="Visitor's Count"
                  name="visitors_count"
                  values={values.visitors_count}
                  onChange={handleChange}
                  // error={touched.visitors_count && errors.visitors_count}
                />
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    key="arrival-time-picker"
                    label="Arrival Time (Expected)"
                    values={selectedArrivalDateTime}
                    onChange={handleArrivalDateTimeChange}
                  />
                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    key="departure-time-picker"
                    label="Departure Time (Expected)"
                    values={selectedDepartureDateTime}
                    onChange={handleDepartureDateTimeChange}
                  />
                </DemoContainer>
              </LocalizationProvider>

              <div className=" grid grid-cols-1 md:grid-cols-2">
                <label
                  htmlFor="send_message"
                  className="inline-flex items-center"
                >
                  <input
                    type="checkbox"
                    id="send_message"
                    name="send_message"
                    checked={values.send_message}
                    onChange={() => setSendMessage(!sendMessage)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    Send Message
                  </span>
                </label>
              </div>
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button type="submit" name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GenerateGatePassForm;
