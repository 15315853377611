import React, { useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import {
  addItems,
  invoiceList,
} from "../../data/local/reducers/miscellaneous.reducer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";
import { AmountFormatter, invoice } from "../../utils/constant";

function AddNewItemForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const [savedVAT, setSavedVAT] = useState(false);
  const { phoneNumbers } = useParams();

  const generateId = () => {
    const number = "012";
    const generatedNum = `${Date.now()}${number.substring(1, 4)}`;
    return generatedNum;
  };

  const initialValues = {
    item_list: [{ item_name: "", unit_price: "", quantity: "", price: "" }],
  };

  const validationSchema = Yup.object({
    item_list: Yup.array().of(
      Yup.object().shape({
        item_name: Yup.string().required("Required"),
        unit_price: Yup.number().required("Required"),
        quantity: Yup.number().required("Required"),
      })
    ),
  });

  // Print single transaction
  const handleSinglePrint = async (Content) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    // Use html2canvas to capture the entire content of printWindow
    printWindow.document.write(Content);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 300);
  };

  const onSubmit = async (values, { resetForm }) => {
    const { item_list } = values;
    const totalPrice = values.item_list.reduce(
      (acc, item) => acc + Number(item.price || 0),
      0
    );

    const vat = totalPrice * 0.075;

    const formData = {
      item_list: JSON.stringify(item_list),
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      phonenumber: phoneNumbers,
      invoice_id: generateId(),
      amount_charge: savedVAT === false ? totalPrice : totalPrice + vat,
      total: totalPrice,
      vat: savedVAT === false ? 0 : vat,
      customer_id: phoneNumber,
    };
    const { payload } = await dispatch(addItems(formData));
    if (payload.status_code === "0") {
      const { payload } = await dispatch(invoiceList(formData));
      if (payload.status_code === "0") {
        const items = payload["item_list"];
        const invoiceId = payload["item_list"][0]["invoice_id"];
        const itemsHtml = items
          .map(
            (item, index) =>
              `<div class="four_col" key=${index}>
            <div class="column">${item.product}</div>
            <div class="column">${item.quantity}</div>
            <div class="column">${AmountFormatter({
              amount: item.unit_price,
            })}</div>
            <div class="column">${AmountFormatter({
              amount: item.price,
            })}</div>
          </div>`
          )
          .join("");
        const htmlSingleContent = invoice(
          itemsHtml,
          payload.item_summary,
          payload.my_profile,
          data,
          invoiceId,
        );
        handleSinglePrint(htmlSingleContent);
      }
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="item_list">
              {({ push, remove }) => (
                <div>
                  <div className="flex justify-end mr-4">
                    <button
                      type="button"
                      name="Add Item"
                      className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 font-semibold px-3 py-2 text-sm rounded shadow"
                      onClick={() => push({})}
                    >
                      {" "}
                      Add New Item
                    </button>
                  </div>
                  {values.item_list.map((item, index) => (
                    <div
                      key={index}
                      className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4"
                    >
                      <FormikControl
                        control="input"
                        type="text"
                        label="Item Name:"
                        name={`item_list[${index}].item_name`}
                      />
                      <FormikControl
                        control="input"
                        type="text"
                        label="Unit Price:"
                        name={`item_list[${index}].unit_price`}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setFieldValue(
                            `item_list[${index}].unit_price`,
                            newValue
                          );
                          const quantity = values.item_list[index].quantity;
                          if (quantity !== "") {
                            setFieldValue(
                              `item_list[${index}].price`,
                              newValue * quantity
                            );
                          }
                        }}
                      />
                      <FormikControl
                        control="input"
                        type="text"
                        label="Quantity:"
                        name={`item_list[${index}].quantity`}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setFieldValue(
                            `item_list[${index}].quantity`,
                            newValue
                          );
                          const unitPrice = values.item_list[index].unit_price;
                          if (unitPrice !== "") {
                            setFieldValue(
                              `item_list[${index}].price`,
                              newValue * unitPrice
                            );
                          }
                        }}
                      />
                      <FormikControl
                        control="input"
                        type="text"
                        label="Price:"
                        name={`item_list[${index}].price`}
                        disabled
                      />
                      <div className="flex justify-start">
                        <button
                          type="button"
                          name="Remove"
                          className="text-red-500 hover:text-white border border-red-700 hover:bg-red-800 font-semibold px-3 py-2 text-sm rounded shadow"
                          onClick={() => remove(index)}
                        >
                          {" "}
                          Delete Item
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            <div className="px-4 text-sm items-center text-slate-600 mb-10 flex gap-3">
              <input
                type="checkbox"
                name="Add VAT"
                id="save_VAT"
                checked={values.send_message}
                onChange={() => setSavedVAT(!savedVAT)}
              />
              <span>Calculate VAT on this purchase</span>
            </div>
            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <div className="md:col-start-2 flex  justify-end">
                <Button name="Submit" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddNewItemForm;
