 import Swal from 'sweetalert2';
import logo from "../images/zippylogo.png";

export const showErrorToast = (errorMessage) => {
  Swal.fire({
    imageUrl: logo ,
    imageHeight: 50,
    width: 300,
    padding: '0.5em',
    imageWidth: 50,
    title: 'Error',
    text: errorMessage,
    confirmButtonText: 'Cancel',
    confirmButtonColor: '#127EC8',
    showCloseButton: true,
  });
   
  return null; 
};

export const showSuccessToast = (successMessage) => {
  Swal.fire({
    imageUrl: logo ,
    imageHeight: 50,
    width: 300,
    padding: '0.5em',
    imageWidth: 50,
    title: 'success',
    text: successMessage,
    // showCloseButton: true,
  });

  return null; 
}

export const ShowPassDetails = (title, message) => {
  Swal.fire({
    imageUrl: logo,
    imageHeight: 50,
    width: 500,
    padding: "0.5em",
    imageWidth: 50,
    title: title,
    //text: message,
    // showCloseButton: true,
   html: `<div style="text-align: left">${message}</div>`,
  });

  return null; // We don't need to render anything for this component
};

export const showInfoToast = (successMessage) => {
  Swal.fire({
    imageUrl: logo ,
    imageHeight: 50,
    width: 300,
    padding: '0.5em',
    imageWidth: 50,
    title: 'Alert',
    text: successMessage,
    // showCloseButton: true,
  });

  return null; 
}


// export const  extractServerError = (error) =>  {
//   const navigate = useNavigate();
//   let extracted;
//   if (error.isAxiosError) {
//     if (error.response) {
//       extracted = [error.response.data.message || "Server Error"];
//     } else if (error.request) {
//       // Redirect to a specific page if a network error occurs
//       navigate('/network-error-page'); // Replace '/network-error-page' with your desired URL
//       extracted = ["Network error occurred"];
//     } else {
//       extracted = ["An unexpected error occurred"];
//     }
//   } else {
//     extracted = [error.message || "An unexpected error occurred"];
//   }
//   extracted.forEach((e) => showErrorToast(e));
// }