import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Banner from "../../Components/Dashboard/Banner";
import { NavLink, Route, Routes } from "react-router-dom";
import Arrow from "../../images/arrd.png";
import MainDashboard from "../MainDashboard";
import User from "../User";
import BulkOperations from "../BulkOperations";
import BuyElectricity from "../BuyElectricity";
import Exams from "../Exams";
import MoneyTransfer from "../MakeTransfer";
import BankTransfer from "../MakeTransfer/bankTransfer";
import WalletTransfer from "../MakeTransfer/walletTransfer";
import PrivateEstate from "../PrivateEstate";
import VehicleRegistration from "../Vehicle";
import WasteTransformer from "../WasteTransformer";
import Recharge from "../RechargeAccount";
import TvSubscription from "../TvSubscription";
import Gotv from "../TvSubscription/gotv";
import Startimes from "../TvSubscription/startimes";
import Airtime from "../RechargeAccount/airtime";
import Data from "../RechargeAccount/data";
import Referral from "../Referral";
import power from "../../images/power.svg";
import dashboard from "../../images/dashboard.svg";
import referral from "../../images/referral.svg";
import services from "../../images/services.svg";
import store from "../../images/store.svg";
import Logout from "../Logout";
import BulkAirtime from "../BulkOperations/bulkairtime";
import BulkData from "../BulkOperations/bulkdata";
import Profile from "../Profile/index";
import Transaction from "../Transaction";
import ShagoElectricity from "../BuyElectricity/ShagoElectricity";
import DMLElectricity from "../BuyElectricity/DMLElectricity";
import PrivateEstateOption from "../PrivateEstate/estateOption";
import PrivateElectricity from "../PrivateEstate/estateElectricity";
import GenerateGatePass from "../PrivateEstate/generateGatePass";
import ValidateGatePass from "../PrivateEstate/validateGatePass";
import TokenHistory from "../PrivateEstate/tokenHistory";
import FibreOption from "../PrivateEstate/fibreOption";
import IndoorFibre from "../PrivateEstate/indoorFibre";
import FibreSubscription from "../PrivateEstate/fibreSubscription";
import DstvDiamond from "../TvSubscription/dstvDiamond";
import DstvPlatinums from "../TvSubscription/dstvPlatinum";
import FundWallet from "../MakeTransfer/fundWallet";
import TopUpWallet from "../MakeTransfer/topUpWallet";
import ProfileManagement from "../Profile/index";
import AccountDetails from "../Profile/AccountDetails";
import SelectService from "../TvSubscription/selectService";
import SelectPlatinum from "../TvSubscription/selectPlatinum";
import SecurityDetails from "../Profile/SecurityDetails";
import UpdateId from "../Profile/UpdateValidId";
import SoftPOS from "../Profile/SoftPOS";
import ResetSecurity from "../Profile/resetSecurity";
import ChangeSecurityAnswer from "../Profile/changeSecurityAnswer";
import TransactionReceipt from "../../utils/transactionReceipt";
import InvoiceOption from "../Invoicing/invoiceOptions";
import CreateProfileScreen from "../Invoicing/createProfile";
import AddProductScreen from "../Invoicing/addProducts";
import EditProfileScreen from "../Invoicing/editProfile";
import UploadLogo from "../Invoicing/uploadLogo";
import GenerateInvoiceScreen from "../Invoicing/generateInvoice";
import AddNewCustomer from "../Invoicing/addNewCustomer";
import AddNewItems from "../Invoicing/addNewItem";
import HistoryScreen from "../Invoicing/history";
import InvoiceReport from "../Invoicing/invoiceReport";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../data/local/reducers/user.reducer";
import usePageTracking from "../../usePageTracking";
import Error404 from "../../Components/Error404";
import RetrievePinScreen from "../Exams/retrievePin";
import VendPinScreen from "../Exams/vendPin";
import BuyPinScreen from "../Exams/buyPin";
import JambHistory from "../Exams/jambHistory";
import BulkVend from "../Exams/bulkVend";
import BulkTransfer from "../BulkOperations/bulkTransfer";
import ReactGA from "react-ga4";
import LogoutTimer from "../../Components/LogOut";
import PrivacyPolicy from "../PrivacyPolicy";
import TransactionStatement from "../../Components/printTransaction";
import TransactionPin from "../Profile/transactionPin";
import RemitaList from "../Remita";
import RRRLookup from "../Remita/rrrLookup";
import ExamOptions from "../Exams";
import JambOption from "../Exams/jambOptions";
import WaecOptions from "../Exams/waecOptions";
import ResultPinScreen from "../Exams/resultPin";
import RegistrationTokenScreen from "../Exams/registrationToken";
import InitiateBillPayment from "../Remita/inititateBillPayment";
import WaecHistory from "../Exams/waecHistory";

const drawerWidth = 240;
const TRACKING_ID = process.env.REACT_APP_ANALYTICS;

ReactGA.initialize(TRACKING_ID);
function Dashboard(props) {
  const location = useLocation();

  useEffect(() => {
    document.title = `zippyworld- ${location.pathname}`;
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title,
    });
  }, [location.pathname, location.state]);
  const isAuthenticated = useSelector((state) => state.user.loginProfile);
  const dispatch = useDispatch();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [sidebar, setSidebar] = React.useState([
    {
      name: "Dashboard",
      icon: <img src={dashboard} alt="" />,
      link: "/dashboard",
      id: "dashbord",
      marginTop: 2.5,
    },
    {
      name: "Report",
      icon: <img src={referral} alt="" />,
      link: "/referral",
      id: "referral",
    },
    {
      name: "Store",
      icon: <img src={store} alt="" />,
      link: "#",
      id: "store",
    },
    // {
    //   name: "Profile management",
    //   icon: <img src={profile} alt="" />,
    //   link: "/profileManagement",
    //   id: "profileManagement",
    // },
    // please make this a dropdown
    {
      name: "Services",
      icon: <img src={services} alt="" />,
      link: "#",
      id: "services",
      isOpen: false,
      isMenu: true,
      subMenu: [
        {
          name: "Airtime and data",
          link: "/rechargeaccount",
          id: "transfer",
        },
        {
          name: "Money transfer",
          link: "/transfer",
          id: "transfer",
        },
        {
          name: "Tv subscription",
          link: "/subscription",
          id: "transfer",
        },
        {
          name: "Electricity",
          link: "/electricity",
          id: "transfer",
        },
        // {
        //   name: "Vehicle registration",
        //   link: "/transfer",
        //   id: "transfer",
        // },
        // {
        //   name: "Exams",
        //   link: "/transfer",
        //   id: "transfer",
        // },
        {
          name: "Private estates",
          link: "/privateestate",
          id: "transfer",
        },
        {
          name: "Bulk operations",
          link: "/bulkOperations",
          id: "exam",
        },

        // {
        //   name: "Waste transformers Limited",
        //   link: "/rechargeaccount",
        //   id: "recharge",
        // },

        {
          name: "Invoice",
          link: "/invoiceOption",
          id: "transfer",
        },
      ],
    },
    {
      name: "Sign out",
      icon: <img src={power} alt="" />,
      color: "text-[#F9533C]",
      link: "/logout",
      id: "signout",
    },
  ]);

  if (!isAuthenticated) {
    dispatch(logout());
    return <Logout />;
  }
  const drawer = (
    <div>
      <Divider />
      <List>
        {sidebar.map((text, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            onClick={(e) => {
              let sideBar = [...sidebar];
              let obj = sideBar[index];
              obj.isOpen = !obj.isOpen;
              setSidebar(sideBar);
            }}
          >
            <NavLink to={text.link}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: "initial",
                  px: 2.5,
                  marginBottom: 1.8,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 2,
                    justifyContent: "center",
                    height: 16,
                  }}
                >
                  {text.icon}
                </ListItemIcon>

                <ListItemText
                  className={`${text.color}`}
                  primary={text.name}
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontFamily: "nunito",
                    fontWeight: "500",
                  }}
                  sx={{ opacity: 1 }}
                />
                {text.isMenu ? <img src={Arrow} alt="arrow" /> : null}
              </ListItemButton>
            </NavLink>
            {text.isMenu && text.isOpen
              ? text.subMenu.map((i, index) => (
                  <p
                    key={index}
                    className="pl-14 text-start py-3 text-xs text-gray-500 hover:text-button-color"
                  >
                    <NavLink to={i.link}>{i.name}</NavLink>
                  </p>
                ))
              : null}
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      <Banner handleDrawerToggle={handleDrawerToggle} />

      <div className=" flex">
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },

              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>

          <div
            className=" bg-white border-r border-gray-100 h-screen hidden md:block overflow-y-auto"
            sx={{
              display: { xs: "none", sm: "block" },
              zIndex: "-1",
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </div>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <div className="">
            <LogoutTimer />
            <Routes>
              <Route path="/dashboard" element={<MainDashboard />} />
              <Route path="/user" element={<User />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/bulkOperations" element={<BulkOperations />} />
              <Route path="/bulkAirtime" element={<BulkAirtime />} />
              <Route path="/bulkData" element={<BulkData />} />
              <Route path="/electricity" element={<BuyElectricity />} />
              <Route
                path="/electricityDiamond"
                element={<ShagoElectricity />}
              />
              <Route path="/electricityPlatinum" element={<DMLElectricity />} />
              <Route path="/exam" element={<ExamOptions />} />
              <Route path="/jamb" element={<JambOption />} />
              <Route path="/waec" element={<WaecOptions />} />
              <Route path="/estateOption" element={<PrivateEstateOption />} />
              <Route path="/transfer" element={<MoneyTransfer />} />
              <Route
                path="/estateElectricity"
                element={<PrivateElectricity />}
              />
              <Route path="/bulkTransfer" element={<BulkTransfer />} />
              <Route path="/retrievePin" element={<RetrievePinScreen />} />
              <Route path="/resultPin" element={<ResultPinScreen />} />
              <Route path="/registrationToken" element={<RegistrationTokenScreen />} />
              <Route path="/vendPin" element={<VendPinScreen />} />
              <Route path="/buyPin" element={<BuyPinScreen />} />
              <Route path="/jambHistory" element={<JambHistory />} />
              <Route path="/waecHistory" element={<WaecHistory />} />
              <Route path="/bulkVend" element={<BulkVend />} />
              <Route path="/generateGatePass" element={<GenerateGatePass />} />
              <Route path="/validateGatePass" element={<ValidateGatePass />} />
              <Route path="/banktransfer" element={<BankTransfer />} />
              <Route path="/wallettransfer" element={<WalletTransfer />} />
              <Route path="/fundWallet" element={<FundWallet />} />
              <Route path="/topUpWallet" element={<TopUpWallet />} />
              <Route path="/privateestate" element={<PrivateEstate />} />
              <Route path="/tokenHistory" element={<TokenHistory />} />
              <Route path="/fibreOptions" element={<FibreOption />} />
              <Route path="/indoorFibre" element={<IndoorFibre />} />
              <Route path="/resetPin" element={<ResetSecurity />} />
              <Route path="/transferReceipt" element={<TransactionReceipt />} />

              <Route
                path="/changeSecurity"
                element={<ChangeSecurityAnswer />}
              />
              <Route
                path="/fibreSubscription"
                element={<FibreSubscription />}
              />
              <Route
                path="/vehicleregistration"
                element={<VehicleRegistration />}
              />
              <Route path="/" />
              <Route path="/wastetransformer" element={<WasteTransformer />} />
              <Route path="/rechargeaccount" element={<Recharge />} />
              <Route path="/subscription" element={<TvSubscription />} />
              <Route path="/dstvPlatinum" element={<DstvPlatinums />} />
              <Route path="/dstvDiamond" element={<DstvDiamond />} />
              <Route path="/selectService" element={<SelectService />} />
              <Route path="/selectPlatinum" element={<SelectPlatinum />} />
              <Route path="/gotv" element={<Gotv />} />
              <Route path="/airtime" element={<Airtime />} />
              <Route path="/invoiceOption" element={<InvoiceOption />} />
              <Route path="/createProfile" element={<CreateProfileScreen />} />
              <Route path="/saveProduct" element={<AddProductScreen />} />
              <Route path="/editProfile" element={<EditProfileScreen />} />
              <Route path="/newCustomer" element={<AddNewCustomer />} />
              <Route path="/invoiceHistory" element={<HistoryScreen />} />
              <Route path="/invoiceReport" element={<InvoiceReport />} />
              <Route path="/addItem/:phoneNumbers" element={<AddNewItems />} />
              <Route path="/uploadLogo" element={<UploadLogo />} />
              <Route path="/printReceipts" element={<TransactionStatement />} />
              <Route path="/remita" element={<RemitaList />} />
              <Route path="/RRRLookup" element={<RRRLookup />} />
              <Route path="/billPayment" element={<InitiateBillPayment />} />
              <Route
                path="/generateInvoice"
                element={<GenerateInvoiceScreen />}
              />
              <Route path="/data" element={<Data />} />

              <Route path="/startimes" element={<Startimes />} />
              <Route path="/transactionlist" element={<Transaction />} />
              <Route path="/referral" element={<Referral />} />
              <Route
                path="/profileManagement"
                element={<ProfileManagement />}
              />
              <Route path="/SecurityDetails" element={<SecurityDetails />} />
              <Route path="/transactionPin" element={<TransactionPin />} />
              {/* <Route path='/ResetSecurityAnswer' element={<ResetSecurity/>} /> */}
              <Route path="/updateID" element={<UpdateId />} />
              <Route path="/softPOS" element={<SoftPOS />} />
              <Route path="/AccountDetails" element={<AccountDetails />} />
              <Route path="*" element={<MainDashboard />} />
              <Route path="/logout" element={<Logout />} />
              {/* <Route component={NotFoundPage} /> */}
              <Route path="*" element={<Error404 />} />
            </Routes>
          </div>
        </Box>
      </div>
    </div>
  );
}
Dashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Dashboard;
