import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Banner({ handleDrawerToggle }) {
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  // Access the properties of loginProfile and verifyBVNProfile objects
  const firstName = loginProfile.user_detail[0].firstname;
  const lastName = loginProfile.user_detail[0].lastname;
  return (
    <div className="bg p-3 flex items-center justify-between text-white">
      <div className="flex gap-4 items-center">
        <div>
          {/* <img src={Avatar} alt="" className='h-12 w-12'/> */}
          <span className="w-12 h-12 grid place-content-center bg-white/25 text-white rounded-full">
            <p>
              {firstName.charAt(0)}
              {lastName.charAt(0)}
            </p>
          </span>
        </div>
        <div className="gap-1 grid lg:flex lg:gap-2 items-center">
          <span className="text-sm font-medium">Hello, {firstName}!</span>
          <Link to="/profile">
            <span className="text-xs font-medium opacity-50">
              (Edit profile)
            </span>
          </Link>
        </div>
      </div>
      <div>
        {/* <img src={Power} alt="" className='hidden md:block'/> */}
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon className=" text-white" />
        </IconButton>
      </div>
    </div>
  );
}

export default Banner;
