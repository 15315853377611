import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import Mobile from "../../images/9mobile.svg";
import Airtel from "../../images/Airtel.svg";
import Glo from "../../images/Glo.svg";
import Mtn from "../../images/MTN.svg";
import { useDispatch, useSelector } from "react-redux";
import { networkOperators } from "../../data/local/reducers/miscellaneous.reducer";
import Loading from "../SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";
import { dataPurchase } from "../../data/local/reducers/miscellaneous.reducer";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BulkDataForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const [networkCode, setSelectedNetworkCode] = useState(null);
  const dataPlan = users.miscellaneous;
  const [isLoading, setIsLoading] = useState(false);

  const handleNetworkChange = (event) => {
    const networkCodes = event.target.value;
    setSelectedNetworkCode(networkCodes);
    const serviceCode = "ZWSC02";
    const { payload } = dispatch(
      networkOperators({ service_code: serviceCode, networkCodes })
    );
  };

  const initialValues = {
    walletaccount: "",
    network_code: "",
    plan_code: "",
    uploadfile: null,
    password: "",
  };

  const validationSchema = Yup.object({
    uploadfile: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  async function generatePDF(bulkUploadRecords) {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;
    const lineHeight = fontSize * 1.2;
    const margin = 50;
    let y = page.getHeight() - margin;
    for (let i = 0; i < bulkUploadRecords.length; i++) {
      const record = bulkUploadRecords[i];
      page.drawText(record, {
        x: margin,
        y,
        size: fontSize,
        font,
        lineHeight,
      });

      y -= lineHeight;
    }

    const pdfBytes = await pdfDoc.save();
    // Return the PDF file as bytes
    return pdfBytes;
  }

  const onSubmit = async (values, { resetForm }) => {
    const dataPlans = values.plan_code;
    const amounts = dataPlans.split("|")[1];
    const planCodes = dataPlans.split("|")[0];
    const { amount = amounts } = values;
    const datass = { plan_code: planCodes, network_code: networkCode, amount };

    const file = values.uploadfile;
    const reader = new FileReader();
    // Define a promise for reading the file
    const fileReadPromise = new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
    const bulkUploadRecords = [];
    // Start reading the file as text
    reader.readAsText(file);
    try {
      setIsLoading(true);
      const fileContents = await fileReadPromise;
      const records = fileContents.split("\n");
      // Send each record to the API
      for (let i = 0; i < records.length; i++) {
        const line = records[i];
        const [phone_number] = line.split(",").map((item) => item.trim());
        const lineData = {
          ...datass,
          beneficiary_phonenumber: phone_number,
          amount: values.plan_code.split("|")[1],
          plan_code: values.plan_code.split("|")[0],
          network_code: networkCode,
          security_answer: values.password,
          transaction_id: generateTransactionId(),
          channel: "web",
          user_type: data.user_type,
          token: token,
          operation_summary: "data_purchase",
          service_code: "ZWSC02",
          phonenumber: data.phonenumber,
        };

        const { payload } = await dispatch(dataPurchase(lineData));

        // Format the record
        const record = `${i + 1}. ${phone_number} ${payload.status_message} ${
          "status:" + payload.status_message
        }`;

        // Add the formatted record to the bulkUploadRecords array
        bulkUploadRecords.push(record);

      }
      dispatch(
        fetchUserBalance({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      dispatch(
        fetchTransactionList({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      resetForm();
      setIsLoading(false);
      const pdfBytes = await generatePDF(bulkUploadRecords);
      //Save the PDF file using FileSaver.js
      saveAs(
        new Blob([pdfBytes], { type: "application/pdf" }),
        "bulk_upload_records.pdf"
      );
      //Show the toast message with the bulk upload records
      showSuccessToast("Bulk Operation Completed");
    } catch (error) {
      console.error("Error reading file:", error);
      showSuccessToast("An error occurred during bulk upload.");
    }
    setIsLoading(false);
  };
  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, touched, setFieldValue }) => (
          <Form>
            <p className="px-4 text-sm mb-2">Select Network Operator:</p>
            <div className="px-4 pb-4 grid grid-cols-2 md:grid-cols-3">
              <label
                className={`p-1 cursor-pointer ${
                  values.network_code === "ZWNO04"
                    ? "border border-[#006E53]"
                    : ""
                }`}
              >
                <Field
                  type="radio"
                  name="network_code"
                  value="ZWNO04"
                  className="hidden"
                  onChange={handleNetworkChange}
                />
                <LazyLoadImage
                  src={Mobile}
                  className="w-full h-full rounded-md"
                  alt="9mobile"
                />
              </label>
              <label
                className={`p-1 cursor-pointer ${
                  values.network_code === "ZWNO01"
                    ? "border border-[#E20010]"
                    : ""
                }`}
              >
                <Field
                  type="radio"
                  name="network_code"
                  value="ZWNO01"
                  className="hidden"
                  onChange={handleNetworkChange}
                />
                <LazyLoadImage
                  src={Airtel}
                  className="w-full h-full rounded-md"
                  alt="Airtel"
                />
              </label>
              <label
                className={`p-1 cursor-pointer ${
                  values.network_code === "ZWNO02"
                    ? "border border-[#93DF7E]"
                    : ""
                }`}
              >
                <Field
                  type="radio"
                  name="network_code"
                  value="ZWNO02"
                  className="hidden"
                  onChange={handleNetworkChange}
                />
                <LazyLoadImage src={Glo} className="w-full h-full rounded-md" alt="Glo" />
              </label>
              <label
                className={`p-1 cursor-pointer ${
                  values.network_code === "ZWNO03"
                    ? "border border-[#FBCA70]"
                    : ""
                }`}
              >
                <Field
                  type="radio"
                  name="network_code"
                  value="ZWNO03"
                  className="hidden"
                  onChange={handleNetworkChange}
                />
                <LazyLoadImage src={Mtn} className="w-full h-full rounded-md" alt="MTN" />
              </label>
            </div>

            <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
              <FormikControl
                control="input"
                type="text"
                label="Wallet number:"
                name="walletaccount"
                value={phoneNumber}
                disabled
              />

              <FormikControl
                control="selectData"
                value={values.data_plan}
                options={dataPlan ?? []}
                type="integer"
                label="Data Plan:"
                name="plan_code"
              />

              <input
                type="file"
                id="uploadfile"
                name="uploadfile"
                onChange={(event) => {
                  setFieldValue("uploadfile", event.currentTarget.files[0]);
                }}
                className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
              />

              <FormikControl
                control="input"
                type="password"
                label="Security Answer:"
                name="password"
              />
            </div>
            <div className="grid grid-cols-1 px-4 md:grid-cols-2">
              <Button name={"Continue"} type="submit" />
            </div>
            {isLoading && <Loading open={isLoading} />}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BulkDataForm;
