import React, { useState } from "react";
import Back from "../../Components/Back";
import StoreAd from "../../Components/storeAdvert";
import { useSelector, useDispatch } from "react-redux";
import { AmountFormatter } from "../../utils/constant";
import Loading from "../../Components/SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";
import { validIdUpload } from "../../data/local/reducers/miscellaneous.reducer";

function UpdateId() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const identityUploads = async (event) => {
    event.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("upload_logo", file);
      formData.append("customer_id", phoneNumber);
      const { payload } = await dispatch(validIdUpload(formData));
      const { result } = payload;
      if (payload.status_code === "0") {
        showSuccessToast(payload.message);
        setFile(null);
      }
    }
  };
  return (
    <div>
      <Loading open={users.loading} />
      <div className="flex items-center gap-4 pb-4 text-sm font-bold border-b">
        <Back />
        <span>Update Valid ID</span>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-8">
        <div className="lg:col-span-2">
          <div className="text-sm border rounded-lg">
            {/* <div className="flex items-center justify-between p-4 border-b">
              <p className="font-bold">
                BVN status:
                <span className="text-xs p-2 ml-4 bg-[#22AD8020] text-[#22AD80] rounded font-medium">
                  active
                </span>
              </p>
            </div> */}
            <form onSubmit={identityUploads}>
              <div className="px-4 py-6 grid grid-cols-1 md:grid-cols-3 gap-4">
                <input
                  type="file"
                  accept="image/*"
                  name=""
                  onChange={handleFileChange}
                  className="border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 file:py-4 file:px-4 file:bg-[#127EC8] file:text-white file:text-xs file:border-0 md:col-span-2"
                />
                <button className="text-xs border bg-[#127EC8] text-white rounded ">
                  Upload ID
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5">
          <StoreAd />
        </div>
      </div>
    </div>
  );
}

export default UpdateId;
