import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import {
  processRemitaPayment,
  rrrLookup,
} from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../SelectValue/Loading";
import { AmountFormatter, jambTransactionReceipt, remitaReceipt } from "../../utils/constant";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";

function RRRLookupForm() {
  const [value, setValue] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [isRRRValidated, setIsRRRValidated] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const [lookUpValues, setLookUpValues] = useState(null);
  const fullName = data.firstname + data.lastname;
  const accountNumber = data.account_no;

  const handleClose = () => setValue(false);

  const initialValues = {
    rrr_number: "",
    amount: "",
    name: "",
    billerId: "",
    billerName: "",
    CategoryName: "",
    phonenumber: phoneNumber,
    email: data.email,
    transactionRef: "",
    description: "",
    fee: "",
    rrrAmount: "",
  };

  const validationSchema = Yup.object({
    rrr_number: Yup.string().required("Required"),
  });



  const verifyRRRNumber = async (rrr_number, setFieldValue) => {
    if (rrr_number?.length === 12) {
      const { payload } = await dispatch(rrrLookup(rrr_number));
      if (payload) {
        setLookUpValues(payload.data);
        setFieldValue("amount", payload.data.amount);
        setFieldValue("name", payload.data.name);
        setFieldValue("billerId", payload.data.billerId);
        setFieldValue("billerName", payload.data.billerName);
        setFieldValue("CategoryName", payload.data.categoryName);
        setFieldValue("transactionRef", payload.data.transactionRef);
        setFieldValue("description", payload.data.description);
        setFieldValue("fee", payload.data.fee);
        setFieldValue("rrrAmount", payload.data.rrrAmount);
        setIsRRRValidated(true);
      } else {
        setIsRRRValidated(false);
      }
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values);
    setValue(true);
    resetForm();
  };

    // Print single transaction
    const handleSinglePrint = async (Content) => {
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(Content);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
      }, 300);
    };

  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values);
    const { rrr_number, transactionRef, amount, description, rrrAmount, fee, email, name } = formValues;
    setValue(true);

    const formData = {
      serviceAmount: amount,
      transactionRef: transactionRef,
      rrr: rrr_number,
      securityAnswer: values.securityanswer,
      channel: "WEB",
      user_type: data.user_type,
      sessionToken: token,
      customerId: data.phonenumber,
      paymentOption: "CASH"
    };
    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(processRemitaPayment(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    if (payload.status_code == "0") {
      console.log('tayealabi yellow' );
      const transactionRefs = payload.data['payByCashResponse']['transactionRef'];
      const transactionStatus = payload.status_code == "0" ? "Successful" : "Failed"
      const feeValue = fee ?? '0.00';
const formattedFee = isNaN(feeValue) ? '0.00' : AmountFormatter({ fee: feeValue }) ?? '0.00';
       handleSinglePrint(
        remitaReceipt(
          rrr_number, transactionRef, amount, description, rrrAmount, formattedFee, email , fullName, accountNumber, transactionRefs, name, transactionStatus
        )
      );
      showSuccessToast(payload.message);
      resetForm();
    }else{
      showErrorToast(payload.message)
    }
  };
  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            {isRRRValidated && (
              <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <p>
                  <strong>AMOUNT:</strong>{" "}
                  {AmountFormatter({
                    amount: values.amount.toString(),
                    currency: "NGN",
                  })}
                </p>
                <p>
                  <strong>FEE:</strong>{" "}
                  {AmountFormatter({
                    amount: values.fee.toString(),
                    currency: "NGN",
                  })}
                </p>
                <p>
                  <strong>RRR AMOUNT:</strong>{" "}
                  {AmountFormatter({
                    amount: values.rrrAmount.toString(),
                    currency: "NGN",
                  })}
                </p>
                <p>
                  <strong>DESCRIPTION:</strong> {values.description}
                </p>
              </div>
            )}
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.rrr_number}
                type="text"
                label="RRR NUMBER:"
                name="rrr_number"
                onChange={(e) => {
                  handleChange(e);
                  verifyRRRNumber(e.target.value, setFieldValue);
                }}
              />
              {isRRRValidated && (
                <FormikControl
                  control="input"
                  type="text"
                  value={values.name}
                  label="Name:"
                  name="name"
                />
              )}
            </div>
            {isRRRValidated && (
              <>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormikControl
                    control="input"
                    type="text"
                    value={values.billerId}
                    label="Biller ID:"
                    name="billerId"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    value={values.billerName}
                    label="Biller Name:"
                    name="billerName"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    value={values.CategoryName}
                    label="Category Name:"
                    name="CategoryName"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    value={values.phonenumber}
                    label="Phone Number:"
                    name="phonenumber"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    value={values.email}
                    label="Email:"
                    name="email"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    value={values.transactionRef}
                    label="Transaction Ref:"
                    name="transactionRef"
                  />
                </div>
                <div className="px-4 grid grid-cols-1 md:grid-cols-2">
                  <Button name="Continue" onClick={() => setValue(true)} />
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RRRLookupForm;
