import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import {
  getBillerCategory,
  getBillerCategoryById,
  getBillerProductByBillerId,
  initiateRemitaPayments,
  validateBillerNumber,
  searchBillers,
} from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../SelectValue/Loading";
import RemitaFormikControl from "../SelectValue/remitaFormikControl";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";

function InitiateBillPaymentForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const users = useSelector((state) => state.misc);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const customerId = loginProfile.user_detail[0].phonenumber;
  const email = loginProfile.user_detail[0].email;
  const fullName =
    loginProfile.user_detail[0].firstname +
    loginProfile.user_detail[0].lastname;

  const [billerCategory, setBillerCategory] = useState([]);
  const [billerCategoryId, setBillerCategoryId] = useState([]);
  const [products, setProducts] = useState([]);
  const [productMetadata, setProductMetadata] = useState([]);
  const [amount, setAmount] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({
    payerName: "",
    email: "",
    phoneNumber: "",
    billerId: "",
    billerCategory: "",
    products: "",
    selectedAmount: "",
    customerId: "",
    rsa_pin: "",
    billerIds: "",
    service: "", // Example of metadata field
  });

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(getBillerCategory());
      if (payload.status_code == "0") {
        setBillerCategory(payload.data);
      } else {
        showErrorToast(payload.message);
      }
    };
    fetchData();
  }, [dispatch]);

  const displayCategoryById = async (billerId) => {
    const { payload } = await dispatch(getBillerCategoryById(billerId));
    if (payload) {
      setBillerCategoryId(payload.data);
    }
  };

  const getBillerProductById = async (billerId) => {
    const { payload } = await dispatch(getBillerProductByBillerId(billerId));
    if (payload.status_code === 0) {
      setProducts(payload.data["products"]);
      // showSuccessToast(payload.message);
    } else {
      showErrorToast(payload.message);
    }
  };

  const handleSearchInputChange = async (event) => {
    const billerNumber = event.target.value;
    if (billerNumber) {
      const { payload } = await dispatch(searchBillers(billerNumber));
      if (payload.status_code === 0) {
        setSearchResults(payload.billers);
      }
    } else {
      setSearchResults([]);
    }
  };

  const validateAndUpdateFields = async (
    billPaymentProductId,
    customerId,
    setFieldValue
  ) => {
    const { payload } = await dispatch(
      validateBillerNumber({ billPaymentProductId, customerId })
    );

    if (payload.status_code === 0) {
      const { customerId, name, email } = payload.data;
      setFieldValue("customerId", customerId);
      setFieldValue("payerName", name);
      setFieldValue("email", email || ""); // Handle case where email might be null
      showSuccessToast(payload.message);
    } else {
      showErrorToast(payload.message);
    }
  };

  const handleProductChange = (event, setFieldValue, values) => {
    const selectedProductId = event.target.value;
    const selectedProduct = products.find(
      (product) => product.billPaymentProductId === selectedProductId
    );
    if (selectedProduct) {
      setAmount(selectedProduct.amount);
      setProductMetadata(selectedProduct.metadata.customFields);

      // Preserve existing values
      setFieldValue("products", selectedProductId);
      setFieldValue("selectedAmount", selectedProduct.amount);

      // Set new metadata fields
      selectedProduct.metadata.customFields.forEach((field) => {
        if (!values[field.variable_name]) {
          setFieldValue(field.variable_name, "");
        }
      });
    }
  };

  const listBillers = billerCategory ? [...billerCategory] : [];
  const listBillerId = billerCategoryId ? [...billerCategoryId] : [];
  const listProducts = products ? [...products] : [];

  const validationSchema = Yup.object({
    payerName: Yup.string().required("Payer Name is Required"),
    email: Yup.string().required("Email is Required"),
    phoneNumber: Yup.string().required("Phone Number is Required"),
    //billerId: Yup.string().required("Biller ID is Required"),
    //billerCategory: Yup.string().required("Biller Category is Required"),
    products: Yup.string().required("Biller Product is Required"),
    selectedAmount: Yup.string().required("Amount is Required"),
    ...productMetadata.reduce((acc, field) => {
      acc[field.variable_name] = field.required
        ? Yup.string().required("Required")
        : Yup.string();
      return acc;
    }, {}),
  });

  const handleSearchInputFocus = (setFieldValue) => {
    setSearchInputValue(""); // Clear the search input value
    setSearchResults([]); // Clear search results
    setFieldValue("billerId", "");
    setFieldValue("billerIds", ""); // Reset related fields
    setFieldValue("billerCategory", "");
    setFieldValue("products", "");
    //setFieldValue("billerNumber", "");
  };

  const handleSubmit = async (values) => {
    const {
      products,
      selectedAmount,
      name,
      email,
      phoneNumber,
      ...restValues
    } = values;

    // Construct metadata
    const filteredMetadata = productMetadata.flatMap((field) => {
      if (
        field.type === "multiselect" &&
        Array.isArray(restValues[field.variable_name])
      ) {
        return restValues[field.variable_name].map((value) => ({
          variable_name: value,
          value: "0",
        }));
      }
      return {
        variable_name: field.variable_name,
        value: restValues[field.variable_name] || "0",
      };
    });

    // Constructing the desired format
    const formData = {
      billPaymentProductId: products,
      amount: selectedAmount,
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      customerId: customerId,
      metadata: {
        customFields: filteredMetadata,
      },
    };

    const { payload } = await dispatch(initiateRemitaPayments(formData));
    if (payload.status_code == "0") {
      showSuccessToast(
        `Your RRR Number is ${payload.data["rrr"]} and your transactionRef is  ${payload.data["transactionRef"]}`
      );
    } else {
      showErrorToast(payload.message);
    }

    // Here you can perform further actions like API calls or dispatch actions
  };

  const getFormikControlType = (fieldType) => {
    switch (fieldType) {
      case "alphanumeric":
        return "input";
      case "numeric":
        return "input";
      case "multiselect":
        return "multiselect";
      case "singleselect":
        return "select";
      case "date":
        return "date";
      default:
        return "input";
    }
  };

  useEffect(() => {
    setInitialFormValues((prevValues) => ({
      ...prevValues,
      ...productMetadata.reduce((acc, field) => {
        if (!acc[field.variable_name]) {
          acc[field.variable_name] = field.type === "multiselect" ? [] : "";
        }
        return acc;
      }, {}),
    }));
  }, [productMetadata]);

  return (
    <div>
      <Loading open={users.loading} />
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue, errors, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative">
                <label className=" text-pri-text block" htmlFor="searchBiller">
                  Search Billers:
                </label>
                <input
                  type="text"
                  name="billerNumber"
                  placeholder="Search Billers"
                  value={values.billerNumber}
                  onChange={handleSearchInputChange}
                  onFocus={() => handleSearchInputFocus(setFieldValue)} // Clear selections on focus
                  className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
                />
                {searchResults.length > 0 && (
                  <ul className="absolute border bg-white w-full mt-1 max-h-40 overflow-auto">
                    {searchResults.map((result) => (
                      <li
                        key={result.billerId}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => {
                          setFieldValue("billerIds", result.billerId);
                          setFieldValue("billerCategory", "");
                          setFieldValue("products", "");
                          getBillerProductById(result.billerId);
                          setSearchInputValue(result.billerName);
                          setSearchResults([]);
                        }}
                      >
                        {result.billerName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {!values.billerIds == "" && (
                <>
                  <FormikControl
                    control="input"
                    label="Name of Service:"
                    name="service"
                    value={searchInputValue}
                    //  onChange={handleChange}
                    //  error={touched.email && errors.email}
                  />
                </>
              )}

              {values.billerIds == "" && (
                <>
                  <FormikControl
                    control="selectRemitaCategory"
                    options={listBillers}
                    label="Select biller category:"
                    name="billerId"
                    value={values.billerId || ""}
                    onChange={(event) => {
                      handleChange(event);
                      displayCategoryById(event.target.value);
                      setFieldValue("billerCategory", "");
                      setFieldValue("products", "");
                    }}
                    error={touched.billerId && errors.billerId}
                  />

                  <FormikControl
                    control="selectBillerId"
                    options={listBillerId}
                    label="Select Name of Service:"
                    name="billerCategory"
                    value={values.billerCategory || ""}
                    onChange={(event) => {
                      handleChange(event);
                      getBillerProductById(event.target.value);
                      setFieldValue("products", "");
                    }}
                    error={touched.billerCategory && errors.billerCategory}
                  />
                </>
              )}

              <FormikControl
                control="selectBillerCategory"
                options={listProducts}
                label="Select Products:"
                name="products"
                value={values.products || ""}
                onChange={(event) => {
                  handleChange(event);
                  handleProductChange(event, setFieldValue, values);
                }}
                error={touched.products && errors.products}
                amount={amount}
              />

              <FormikControl
                control="input"
                label="Amount:"
                name="selectedAmount"
                value={values.selectedAmount || ""}
                onChange={handleChange}
                error={touched.selectedAmount && errors.selectedAmount}
              />

              {productMetadata.map((field) => (
                <RemitaFormikControl
                  key={field.variable_name}
                  control={getFormikControlType(field.type)}
                  label={field.display_name}
                  name={field.variable_name}
                  value={values[field.variable_name] || ""}
                  onChange={handleChange}
                  onBlur={async () => {
                    if (field.validation) {
                      const billPaymentProductId = "4111452350";
                      const customerId = values[field.variable_name];
                      await validateAndUpdateFields(
                        billPaymentProductId,
                        customerId,
                        setFieldValue
                      );
                    }
                  }}
                  error={
                    touched[field.variable_name] && errors[field.variable_name]
                  }
                />
              ))}

              <FormikControl
                control="input"
                label="Payer Name:"
                name="payerName"
                value={values.payerName || ""}
                onChange={handleChange}
                error={touched.payerName && errors.payerName}
              />

              <FormikControl
                control="input"
                label="Email:"
                name="email"
                value={values.email || ""}
                onChange={handleChange}
                error={touched.email && errors.email}
              />

              <FormikControl
                control="input"
                label="Phone Number:"
                name="phoneNumber"
                value={values.phoneNumber || ""}
                onChange={handleChange}
                error={touched.phoneNumber && errors.phoneNumber}
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default InitiateBillPaymentForm;
