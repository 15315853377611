import qrcode from "../images/qr-code.png";
import "../transactionReciept.css"
import printJS from "print-js";

const TransactionReceipt = () => {
  function printForm() {
    printJS({
            printable: 'reciept',
            type: 'html',
            targetStyles: ['*'],
        }

    )
}
  return (
    <div className="monoFont" >
      <div className="receiptHeading">
        Transaction receipt.
      </div>
      <div className="transactionBody">
        <div className="serviceInfo">
          <div>Electricity</div>
          <div >-</div>
          <div>₦1500</div>
        </div>
        <div className="transaction_container">
          <p className="opacity">Sender:</p>
          <p className="bottom_line">
            <span>Test Line One</span> <br />
            <span>9605108425 </span> <br />
            <span>Zippyworld.</span>
          </p>
        </div>
        <div className="transaction_container">
          <p className="opacity">Receiver:</p>
          <p className="bottom_line">
            <span>Electricity</span> <br />
            <span>Meter No:43901851444</span> <br />
            <span>Token:3408-1384-4007-5839-9910</span>
          </p>
        </div>
        <div className="transaction_container">
          <p className="opacity">Amount:</p>
          <p className="bottom_line">1000 naira</p>
        </div>
        <div className="transaction_container">
          <p className="opacity">Timestamp:</p>
          <p className="bottom_line">August 10, 2023 at 10:47 AM</p>
        </div>
        <div className="transaction_container">
          <p className="opacity">Description:</p>
          <p className="bottom_line">
            Test Line One sold electricity power of N150.00 to
            ALH.K.N.KAZEEM(43901851444)
          </p>
        </div>
        <div className="two_column_responsive">
          <div>
            <p className="opacity">Reference number:</p>
            <p className="bottom_line truncate_text">
              T2023080823190784785545138T2023080823190784785545138T2023080823190784785545138T2023080823190784785545138
            </p>
          </div>
          <div>
            <p className="opacity">Status:</p>
            <p className="bottom_line">Successful</p>
          </div>
        </div>

        <div className="footer_container">
          <div className="span-1">
            <img src={qrcode} alt="" />
          </div>
          <div className="span-5">
            <p className="text-sm opacity-50">
              This is an electronic receipt of a transaction and does not
              require any signature. The authenticity of transaction can be
              confirmed with the Zippyworld For any other assitance, Kindly call
              True Serve on+234 903 985 5986 or email
              zippyworld@macrotelgroup.co
            </p>
          </div>
        </div>
      </div>

      <button className="print-style" onClick={printForm}>Print receipt</button>
    </div>
  );
};

export default TransactionReceipt;