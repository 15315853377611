import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function SelectData(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="mb-3">
      <label className="text-pri-text block" htmlFor={name}>
        {label}
      </label>
      <Field
        as="select"
        className="mb-1 w-full border rounded-md border-gray-200 p-3 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
        id={name}
        name={name}
        {...rest}
      >
        {/* Add a default option as the first item */}
        <option value="">Select Data</option>

        {Array.isArray(options) &&
          options.map((option, index) => {
            const data = option.amount;
            const extractedNumber = data.match(/\d+/)[0];
            return (
              <option
                key={index}
                value={option.plan_code + "|" + option.amount}
              >
                {options[0].plan_name === "Airtel Data Plan" ||
                options[0].plan_name === "Mtn data_plan"
                  ? option.data_bundle + " @ " + option.amount
                  : option.databundle + " @ " + extractedNumber}
              </option>
            );
          })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default SelectData;
