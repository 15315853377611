import { React } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import {
  validatePass,
} from "../../data/local/reducers/miscellaneous.reducer";

import {useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../SelectValue/Loading";
import {
  ShowPassDetails,
} from "../../utils/api-utils";

function GatePassValidation(props) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);

  const initialValues = {
    generated_token: "",
  };

  const validationSchema = Yup.object({});

  const onSubmit = async (values, { resetForm }) => {
    const { generated_token } = values;

    const formData = {
      token: generated_token,
      estate_code: props.dataReceived,
    };

    // handleClose();
    const { payload } = await dispatch(validatePass(formData));
    if (payload.status_code === "0") {
      const result = payload.result[0];

      const detailsMessage = `
        <b>Visitor Details</b><br>
        <b>Phone Number:</b> ${result.phonenumber}<br>
        <b>Name:</b> ${result.name}<br>
        <b>Gender:</b> ${result.gender}<br>
        <b>Sector:</b> ${result.sector}<br>
        <b>Entry Mode:</b> ${result.entering_mode}<br>
        <b>Plate Number:</b> ${result.number_plate}<br>
        <b>Number of People:</b> ${result.visitor_count}<br>
        <b>Access Mode:</b> ${result.access_mode}<br>
        <b>Arrival Time:</b> ${result.expected_arrival_time}<br>
        <b>Departure Time:</b> ${result.expected_departure_time}<br>
        <b>Address:</b> ${result.address}
      `;

      ShowPassDetails("Estate User Details", detailsMessage);

      resetForm();
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.generated_token}
                type="text"
                label="Enter generated token:"
                name="generated_token"
              />
            </div>
            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button type="submit" name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GatePassValidation;
