import React from 'react'
import { ErrorMessage } from 'formik'
import TextError from './TextError'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css' 

function SelectDate (props) {
  const { label, name, options, ...rest } = props
  return (
    <div className='mb-3'>
      <label className='text-pri-text block' htmlFor={name}>
        {label}
      </label>
      <DatePicker
        className='mb-1 w-full border rounded-md border-gray-200 p-3 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500'
        id={name}
        name={name}
        selected={rest.value} // Use 'selected' prop to pass the date value
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default SelectDate



