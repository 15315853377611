import React from "react";
import FeatureCard from "./FeatureCard";
import agent from "../../images/agent-image.jpg";
import app from "../../images/app.png";
import discount from "../../images/discount.jpg";
import agentIcon from "../../images/store-2-fill.svg";
import downloadIcon from "../../images/download icon.svg";
import discountIcon from "../../images/discount icon.svg";


function Features() {
  const becomeAnAgent =
    "bg-[#127EC820] text-[#127ec8] flex justify-between p-5 rounded-lg mt-3 items-center";
  const getTheApp =
    "bg-[#D3EFE6] text-[#22ad80] p-5 rounded-lg mt-3 items-center space-y-6";
  const discounts =
    "bg-[#F8EEE1] text-[#dcab6b] flex justify-between p-5 rounded-lg mt-3 items-center";

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 px-4 md:px-16 gap-10 py-10">
      <div>
        <FeatureCard
          featureIcon={agentIcon}
          featureTitle="Become An Agent"
          featureBody="Accept payments from your customers with our POS terminals which are secure and reliable for all your card transactions."
          featureImage={agent}
          container="rounded-lg bg-[#127ec820] text-[#127ec8] overflow-hidden"
        />
        <div className={becomeAnAgent}>
          <p className="text-sm">Become an agent</p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>

      <div>
        <FeatureCard
          featureIcon={downloadIcon}
          featureTitle="Get The App"
          featureBody="An even easier way to manage every aspect of your utility payments. Download the app and make payments on the go."
          featureImage={app}
          container="rounded-lg bg-[#D3EFE6] text-[#22AD80] overflow-hidden"
        />
        <div className={getTheApp}>
          <div className="flex justify-between">
            <a
              href="https://play.google.com/store/apps/details?id=ng.com.zippy_world"
              target="blank"
              className="text-sm flex items-center gap-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM8.82258 15.3427C8.42804 14.8663 7.9373 14.6957 7.34401 14.834L7.19355 14.875L6.60484 15.8911C6.37903 16.2863 6.5121 16.7903 6.90726 17.0161C7.26949 17.2231 7.7232 17.1286 7.97023 16.807L8.03226 16.7137L8.82258 15.3427ZM13.2097 8.66129C12.7218 9.06452 12.2298 10.2581 12.9194 11.4476L15.9597 16.7137C16.1895 17.1089 16.6895 17.2419 17.0847 17.0161C17.4469 16.8054 17.5889 16.3677 17.4361 15.9919L17.3871 15.8911L16.5847 14.5H17.7742C18.2298 14.5 18.5968 14.1331 18.5968 13.6774C18.5968 13.2568 18.2841 12.9118 17.8776 12.8612L17.7742 12.8548H15.6331L13.44 9.05741L13.2097 8.66129ZM13.4879 5.61694C13.1257 5.40995 12.672 5.50451 12.4249 5.82608L12.3629 5.91935L11.996 6.55242L11.6371 5.91935C11.4073 5.52419 10.9073 5.39113 10.5121 5.61694C10.1499 5.82762 10.0079 6.26532 10.1606 6.64118L10.2097 6.74194L11.0484 8.19758L8.3629 12.8508H6.26613C5.81048 12.8508 5.44355 13.2177 5.44355 13.6734C5.44355 14.094 5.7562 14.439 6.16268 14.4896L6.26613 14.496H13.746C14.0869 13.8562 13.6854 12.9472 12.9357 12.8579L12.8145 12.8508H10.2621L13.7903 6.74194C14.0161 6.34677 13.8831 5.84274 13.4879 5.61694Z"
                  fill="rgba(34,173,128,1)"
                ></path>
              </svg>
              <span>Download on Google Play Store</span>
            </a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                clipRule="evenodd"
              />
            </svg>
          </div>

          <div className="flex justify-between">
            <a
              href="https://apps.apple.com/us/app/zippyworld/id1554263869"
              target="blank"
              className="text-sm flex items-center gap-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M3.60972 1.81396L13.793 12L3.61082 22.1864C3.41776 22.1048 3.24866 21.962 3.13555 21.7667C3.0474 21.6144 3.00098 21.4416 3.00098 21.2656V2.73453C3.00098 2.32109 3.25188 1.96625 3.60972 1.81396ZM14.5 12.707L16.802 15.009L5.86498 21.342L14.5 12.707ZM17.699 9.50896L20.5061 11.1347C20.9841 11.4114 21.1473 12.0232 20.8705 12.5011C20.783 12.6523 20.6574 12.778 20.5061 12.8655L17.698 14.491L15.207 12L17.699 9.50896ZM5.86498 2.65796L16.803 8.98996L14.5 11.293L5.86498 2.65796Z"
                  fill="rgba(34,173,128,1)"
                ></path>
              </svg>
              <span>Download on Apple App Store</span>
            </a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <FeatureCard
          featureIcon={discountIcon}
          featureTitle="Enjoy Amazing Discounts"
          featureBody="To enjoy amazing discounts on zippyworld, create an account and carry out regular transactions                   ."
          featureImage={discount}
          container="rounded-lg bg-[#F8EEE1] text-[#dcab6b] overflow-hidden"
        />
        <div className={discounts}>
          <p className="text-sm">Check for promo codes</p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Features;
