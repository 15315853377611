import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { useDispatch, useSelector } from "react-redux";
import {
  shagoCardValidation,
  shagoCableTvAddons,
  shagoCableTvSubscription,
  cableBeneficiary,
  saveCableBeneficiaries,
  shagoTopUpCableTvSubscription,
} from "../../data/local/reducers/miscellaneous.reducer";
import Loading from "../SelectValue/Loading";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";

function DstvDiamondForm(props) {
  const [value, setValue] = useState(false);
  const handleClose = () => setValue(false);
  const dispatch = useDispatch();
  const [cardValidation, setCardValidation] = useState([]);
  const [addonsValue, setAddonsValue] = useState([]);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const users = useSelector((state) => state.misc);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProducts, setSelectedProducts] = useState("");
  const [selectedAddon, setSelectedAddon] = useState("");
  const [formValues, setFormValues] = useState(null);
  const { serviceCode, operatorCode } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [savedbeneficiary, setSavedBeneficiary] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
    if (!showDropdown) {
      cableBeneficiaries();
    } else {
      setSelectedBeneficiary(""); // Reset selected beneficiary when hiding the dropdown
    }
  };

  const cableBeneficiaries = async () => {
    const { payload } = await dispatch(
      cableBeneficiary({
        phonenumber: phoneNumber,
        card_type: serviceCode,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    const { result } = payload;
    setBeneficiary(result);
  };

  const cableBenefit = beneficiary ? [...beneficiary] : [];

  const subscriptionOptions = [
    { key: "Select Subscription Type", value: "" },
    { key: "TOPUP", value: "topup" },
    { key: "Subscribe", value: "subscribe" },
  ];

  const verifyMeterNumber = async (meterNumber) => {
    if (
      serviceCode === "startimes"
        ? meterNumber?.length === 11
        : meterNumber?.length === 10
    ) {
      const { payload } = await dispatch(
        shagoCardValidation({
          cable_service_code: serviceCode,
          cable_operator_code: operatorCode,
          card_identity: meterNumber,
          token: token,
          phonenumber: phoneNumber,
        })
      );
      if (payload?.result) {
        const { result } = payload;
        setCardValidation(result);
      } else {
        showErrorToast("Service Not Available");
      }
    }
  };

  const handleProductChange = (value) => {
    setSelectedProducts(value);
  };

  const handleAddonChange = (value) => {
    setSelectedAddon(value);
  };

  const verifyAddons = async (product) => {
    const { payload } = await dispatch(
      shagoCableTvAddons({
        product_code: product.split("|")[2],
      })
    );
    const { result } = payload;
    setAddonsValue(result?.product);
    setSelectedProduct(product);
  };

  const products = cardValidation?.product ? [...cardValidation?.product] : [];

  const addons = addonsValue ? [...addonsValue] : [];

  const parsedSelectedProducts = parseInt(selectedProducts.split("|")[0]);
  const parsedSelectedAddon = parseInt(selectedAddon.split("|")[0]);

  const totalAmount =
    !isNaN(parsedSelectedProducts) && !isNaN(parsedSelectedAddon)
      ? parsedSelectedProducts + parsedSelectedAddon
      : !isNaN(parsedSelectedProducts)
      ? parsedSelectedProducts
      : 0;

  const initialValues = {
    cardId: "",
    customer: "",
    customernumber: "",
    product: "",
    addon: "",
    productcode: ".",
    packagename: ".",
    duration: "",
    totalAmount: "",
    subscription: "",
    total_amount: "",
  };

  const validationSchema = Yup.object({
    // cardId: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    resetForm();
  };
  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values);
    setValue(true);
    const { cardId, subscription, total_amount } = formValues;
    if (savedbeneficiary) {
      const { payload } = await dispatch(
        saveCableBeneficiaries({
          phonenumber: phoneNumber,
          beneficiary_name: cardValidation?.customerName,
          card_type: serviceCode,
          card_identity: cardId,
          customer_number: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
    }

    if (subscription !== "topup") {
      const formData = {
        card_identity: showDropdown
          ? selectedBeneficiary.split("|")[1]
          : cardId,
        subscription: subscription,
        cable_service_code: serviceCode,
        cable_operator_code: operatorCode,
        customer_name: showDropdown
          ? selectedBeneficiary.split("|")[0]
          : cardValidation?.customerName,
        customer_no: showDropdown
          ? selectedBeneficiary.split("|")[2]
          : cardValidation?.customerNumber,
        addon_amount: selectedAddon.split("|")[0],
        addon_product_code: selectedAddon.split("|")[2],
        addon_product_name: selectedAddon.split("|")[3],
        product_amount: selectedProduct.split("|")[0],
        has_addon: selectedAddon === "" ? "0" : "1",
        invoice_period: selectedProducts.split("|")[1],
        package_name: selectedProducts.split("|")[3],
        product_codes: selectedProducts.split("|")[2],
        total_amount: totalAmount,
        security_answer: values.securityanswer,
        transaction_id: generateTransactionId(),
        channel: "web",
        user_type: data.user_type,
        token: token,
        phonenumber: data.phonenumber,
      };
      handleClose();
      const { payload } =
        formData.security_answer !== null || undefined
          ? await dispatch(shagoCableTvSubscription(formData))
          : "";
      dispatch(
        fetchUserBalance({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      dispatch(
        fetchTransactionList({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      if (payload.status_code === "0") {
        resetForm();
        showSuccessToast(payload.message);
      }
    } else {
      const formData = {
        card_identity: showDropdown
          ? selectedBeneficiary.split("|")[1]
          : cardId,
        subscription: subscription,
        cable_service_code: serviceCode,
        cable_operator_code: operatorCode,
        buyer_name: showDropdown
          ? selectedBeneficiary.split("|")[0]
          : cardValidation?.customerName,
        customer_no: showDropdown
          ? selectedBeneficiary.split("|")[2]
          : cardValidation?.customerNumber,
        amount: total_amount,
        security_answer: values.securityanswer,
        transaction_id: generateTransactionId(),
        channel: "web",
        user_type: data.user_type,
        token: token,
        phonenumber: data.phonenumber,
      };
      handleClose();
      const { payload } =
        formData.security_answer !== null || undefined
          ? await dispatch(shagoTopUpCableTvSubscription(formData))
          : "";
      dispatch(
        fetchUserBalance({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      dispatch(
        fetchTransactionList({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      if (payload.status_code === "0") {
        resetForm();
        showSuccessToast(payload.message);
      }
    }
  };

  return (
    <div>
      <Loading open={users && users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
      <div className="flex justify-end mr-4">
        <button
          type="button"
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 font-semibold px-3 py-2 text-sm rounded shadow"
          onClick={handleDropdownToggle}
        >
          Show Beneficiaries
        </button>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {showDropdown && (
                <FormikControl
                  control="selectCableBeneficiary"
                  options={cableBenefit}
                  label="Select  beneficiary:"
                  name="bank_code"
                  value={values.walletBeneficiaries}
                  onChange={(event) => {
                    setSelectedBeneficiary(event.target.value);
                    const selectedMeterNumber =
                      event.target.value.split("|")[1]; // Extract the meter number from the selected beneficiary
                    verifyMeterNumber(selectedMeterNumber);
                    // Update selected beneficiary value
                  }}
                  error={touched.bank_code && errors.bank_code}
                />
              )}
              {serviceCode === "dstv" ? (
                <FormikControl
                  control="select"
                  type="text"
                  options={subscriptionOptions}
                  label="Select Subscription Type:"
                  name="subscription"
                  value={value.subscription}
                  onChange={handleChange}
                />
              ) : null}
              {values.subscription !== "topup" ? (
                <FormikControl
                  control="input"
                  type="integer"
                  label="Smart Card Number:"
                  name="cardId"
                  value={
                    showDropdown
                      ? selectedBeneficiary.split("|")[1]
                      : values.cardId || ""
                  }
                  onChange={(event) => {
                    handleChange(event);
                    verifyMeterNumber(event.target.value);
                  }}
                  error={touched.cardId && errors.cardId}
                />
              ) : (
                <FormikControl
                  control="input"
                  type="integer"
                  label="Smart Card Number:"
                  name="cardId"
                  value={
                    showDropdown
                      ? selectedBeneficiary.split("|")[1]
                      : values.cardId || ""
                  }
                  error={touched.cardId && errors.cardId}
                />
              )}
              {values.subscription !== "topup" ? (
                <FormikControl
                  control="input"
                  type="text"
                  label="Customer name:"
                  name="customer"
                  value={
                    showDropdown
                      ? selectedBeneficiary.split("|")[0]
                      : cardValidation?.customerName || ""
                  }
                  disabled
                />
              ) : null}
              {values.subscription !== "topup" ? (
                <FormikControl
                  control="input"
                  type="integer"
                  label="Customer number:"
                  name="customernumber"
                  value={
                    showDropdown
                      ? selectedBeneficiary.split("|")[2]
                      : cardValidation?.customerNumber || ""
                  }
                  disabled
                />
              ) : null}
              {serviceCode === "startimes" ? (
                <FormikControl
                  control="selectStar"
                  options={products}
                  label="Select product:"
                  name="products"
                  value={selectedProducts || values.product}
                  onChange={(event) => {
                    handleChange(event);
                    handleProductChange(event.target.value);
                  }}
                />
              ) : values.subscription !== "topup" ? (
                <FormikControl
                  control="selectProduct"
                  options={products}
                  label="Select product:"
                  name="products"
                  value={selectedProduct || values.product}
                  onChange={(event) => {
                    handleChange(event);
                    verifyAddons(event.target.value);
                    handleProductChange(event.target.value);
                  }}
                />
              ) : null}

              {/* {values.subscription !== "topup" ? ( 
                <FormikControl
                  control="selectProduct"
                  options={addons}
                  label="Available addons: (optional)"
                  name="addon"
                  id="addon1"
                  //value={selectedAddon || values.addon}
                  onChange={(event) => {
                    handleChange(event);
                    handleAddonChange(event.target.value);
                  }}
                />
              ) : null} */}
              {serviceCode === "dstv" && values.subscription !== "topup" ? (
                <FormikControl
                  control="selectProduct"
                  options={addons}
                  label="Available addons: (optional)"
                  name="addon"
                  //value={selectedAddon || values.addon}
                  onChange={(event) => {
                    handleChange(event);
                    handleAddonChange(event.target.value);
                  }}
                />
              ) : null}
              {values.subscription !== "topup" ? (
                <FormikControl
                  control="input"
                  type="integer"
                  label="Subscription amount:"
                  name="totalAmount"
                  value={totalAmount}
                  disabled
                />
              ) : (
                <FormikControl
                  control="input"
                  type="integer"
                  label="Subscription amount:"
                  name="total_amount"
                  value={values.total_amount}
                />
              )}
            </div>

            {!showDropdown && values.subscription !== "topup" && (
              <div className="px-4 text-sm items-center text-slate-600 mb-10 flex gap-3">
                <input
                  type="checkbox"
                  name="Save beneficiary"
                  id="save_beneficiary"
                  checked={savedbeneficiary}
                  onChange={() => setSavedBeneficiary(!savedbeneficiary)}
                />
                <span>Save as beneficiary</span>
              </div>
            )}

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DstvDiamondForm;
