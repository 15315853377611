import Card from "../../Components/Dashboard/Card";
import Services from "../../Components/Dashboard/Services";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import InflowOutflow from "../../Components/Dashboard/inflow-outflow";

function MainDashboard() {
  return (
    <div className="grid grid-cols-1 gap-5 mt-5 lg:grid-cols-3 text-sm">
      <div className="lg:col-span-2 grid gap-5">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
          <div className="lg:col-span-3">
            <Card />
          </div>
          <div className="lg:col-span-2">
            <InflowOutflow />
          </div>
        </div>
        <div className="">
          <Services />
        </div>
      </div>
      <div className="grid gap-5">
        <StoreAd />
        <RecentTransactions />
      </div>
    </div>
  );
}

export default MainDashboard;
