import React, { useState } from "react";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import { useSelector, useDispatch } from "react-redux";
import { AmountFormatter } from "../../utils/constant";
import Loading from "../../Components/SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";
import { identityUpload } from "../../data/local/reducers/miscellaneous.reducer";
import DownloadTemplate from "../../Components/DownloadTemplate";

function UploadLogo() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const identityUploads = async (event) => {
    event.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("upload_logo", file);
      formData.append("customer_id", phoneNumber);
      const { payload } = await dispatch(identityUpload(formData));
      const { result } = payload;

      if (payload.status_code === "0") {
        showSuccessToast(payload.message);
        setFile(null);
      }
    }
  };
  return (
    <div>
      <Loading open={users.loading} />
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        <span>Upload Invoice Logo</span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <div className="rounded-lg border text-sm">
              <DownloadTemplate />
              <form onSubmit={identityUploads}>
                <div className="px-4 py-6 grid grid-cols-1 md:grid-cols-3 gap-4">
                  <input
                    type="file"
                    accept="image/*"
                    name=""
                    onChange={handleFileChange}
                    className="border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 file:py-4 file:px-4 file:bg-[#127EC8] file:text-white file:text-xs file:border-0 md:col-span-2"
                  />
                  <button
                    type="submit"
                    className="text-xs md:text-sm lg:text-base border bg-[#127EC8] text-white rounded px-4 py-2 md:px-6 md:py-3 lg:px-8 lg:py-4"
                  >
                    Upload ID
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="grid gap-5">
          <StoreAd />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default UploadLogo;
