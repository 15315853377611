import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { registrationToken, resultPinPayment } from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";

function RegistrationToken() {
  const [value, setValue] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const email = loginProfile.user_detail[0].email;
  const fullName =
    loginProfile.user_detail[0].firstname +
    loginProfile.user_detail[0].lastname;

  const initialValues = {
    customerId: phoneNumber,
    candidatePhoneNumber: "",
    numberOfCandidates: "",
  };

  const validationSchema = Yup.object({
    candidatePhoneNumber: Yup.string().required("Required"),
    numberOfCandidates: Yup.string().required("Required"),
  });

  const handleClose = () => setValue(false);

  const handleSinglePrint = async (Content) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(Content);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 300);
  };

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values);
    setValue(true);
    resetForm();
  };

  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values);
    const { candidatePhoneNumber, numberOfCandidates } = formValues;
    setValue(true);

    const formData = {
      phonenumberOfCandidate: candidatePhoneNumber,
      numberOfCandidates: numberOfCandidates,
      customerName: fullName,
      transaction_id: generateTransactionId(),
      channel: "web",
      customerId: phoneNumber,
      email: email,
      sessionToken: token,
      securityAnswer: values.securityanswer,
    };
    handleClose();
    const { payload } = await dispatch(registrationToken(formData));

    if (payload.status_code == '0') {
      resetForm();
      showSuccessToast(
        `Transaction Successful. Your result Token is ${payload.registrationToken} `
      );
      handleSinglePrint(
        `<p>Transaction Successful. Your result Token is ${payload.registrationToken}</p>`
      );
    } else {
      showErrorToast(payload.result.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.customerId}
                type="text"
                label="Recipient wallet number:"
                name="customerId"
                disabled
              />
              <FormikControl
                control="input"
                value={values.candidatePhoneNumber}
                type="text"
                label="Candidate Phone Number:"
                name="candidatePhoneNumber"
                onChange={handleChange}
              />
              <FormikControl
                control="input"
                value={values.numberOfCandidates}
                type="text"
                label="Number of candidates:"
                name="numberOfCandidates"
                onChange={handleChange}
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" onClick={() => setValue(true)} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RegistrationToken;
