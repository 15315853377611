import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../SelectValue/Loading";
import {
  invoiceHistory,
  invoiceList,
} from "../../data/local/reducers/miscellaneous.reducer";
import {
  AmountFormatter,
  FormattedDateNoTime,
  invoice,
  invoiceReceipt,
  waybill,
} from "../../utils/constant";
import { Link } from "react-router-dom";

const History = () => {
  const [receiptData, setReceiptData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const users = useSelector((state) => state.misc);
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(
        invoiceHistory({ customer_id: phoneNumber })
      );
      setReceiptData(payload?.result || null);
    };
    fetchData();
  }, [dispatch, phoneNumber]);

  const listTransaction = (receiptData || []).filter((transaction) =>
    transaction.names.toLowerCase().includes(searchText.toLowerCase())
  );

  const limitedTransactions = listTransaction.slice(startIndex, endIndex);

  // Print single transaction
  const handleSinglePrint = async (Content) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    // Use html2canvas to capture the entire content of printWindow
    printWindow.document.write(Content);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 300);
  };
  const waybillPrint = async (object) => {
    // Your existing logic for printing
    const formData = {
      customer_id: phoneNumber,
      invoice_id: object.invoice_id,
    };
    const { payload } = await dispatch(invoiceList(formData));
    if (payload.status_code === "0") {
      const items = payload["item_list"];
      const invoiceId = payload["item_list"][0]["invoice_id"];
      const itemsHtml = items
        .map(
          (item, index) =>
            `<div class="four_col" key=${index}>
          <div class="column">${item.quantity}</div>
          <div class="column">${item.product}</div>
          <div class="column">${AmountFormatter({
            amount: item.unit_price,
          })}</div>
          <div class="column">${AmountFormatter({
            amount: item.price,
          })}</div>
        </div>`
        )
        .join("");
      const htmlSingleContent = waybill(
        itemsHtml,
        payload.item_summary,
        payload.my_profile,
        data,
        invoiceId
      );
      handleSinglePrint(htmlSingleContent);
    }
  };
  const singlePrint = async (object) => {
    const formData = {
      customer_id: phoneNumber,
      invoice_id: object.invoice_id,
    };
    const { payload } = await dispatch(invoiceList(formData));
    if (payload.status_code === "0") {
      const items = payload["item_list"];
      const invoiceId = payload["item_list"][0]["invoice_id"];

      const itemsHtml = items
        .map(
          (item, index) =>
            `<div class="four_col" key=${index}>
          <div class="column">${item.product}</div>
          <div class="column">${item.quantity}</div>
          <div class="column">${AmountFormatter({
            amount: item.unit_price,
          })}</div>
          <div class="column">${AmountFormatter({
            amount: item.price,
          })}</div>
        </div>`
        )
        .join("");
      const htmlSingleContent = invoice(
        itemsHtml,
        payload.item_summary,
        payload.my_profile,
        data,
        invoiceId
      );
      handleSinglePrint(htmlSingleContent);
    }
  };

  const recieptPrint = async (object) => {
    const formData = {
      customer_id: phoneNumber,
      invoice_id: object.invoice_id,
    };
    const { payload } = await dispatch(invoiceList(formData));
    if (payload.status_code === "0") {
      const htmlSingleContent = invoiceReceipt(
        payload.item_summary,
        payload.my_profile,
        data
      );
      handleSinglePrint(htmlSingleContent);
    }
  };

  return (
    <div className="">
      <Loading open={users.loading} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border-b p-4 items-center">
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Customer Name..."
            className="block p-2 pl-10 text-sm text-black border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>
      <div className="rounded-t-md max-w-full overflow-x-scroll m-4 print-table">
        <table className="w-full text-sm text-left">
          <thead className="text-[#127EC8] bg-[#127EC830]">
            <tr>
              <th className="py-6" style={{ maxWidth: "80px" }}>
                S/N
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                Customer Name
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                Address
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                Email
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                Phone Number
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                Total
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                VAT
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                Date
              </th>
              <th className="px-6" style={{ maxWidth: "150px" }}>
                Action
              </th>
              <th className="px-6" style={{ maxWidth: "150px" }}></th>
              <th className="px-6" style={{ maxWidth: "150px" }}></th>
            </tr>
          </thead>
          <tbody className="">
            {limitedTransactions.map((object, index) => (
              <tr
                className="bg-white border-b text-black text-[13px]"
                key={object.invoice_id}
              >
                <td className="px-6 py-4 font-medium">
                  {startIndex + index + 1}
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  {object.names}
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  {object.address}
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  {object.email}
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  {object.customer_id}
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  <AmountFormatter amount={object.total} currency="NGN" />
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  <AmountFormatter amount={object.vat} currency="NGN" />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <FormattedDateNoTime dateFromTable={object.inserted_dt} />
                </td>
                <td className="pr-10 print-more-info">
                  <button
                    onClick={() => singlePrint(object)}
                    className="rounded-md bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 transition-colors duration-300 ease-in-out focus:outline-none"
                  >
                    Invoice
                  </button>
                </td>
                <td className="pr-10 print-more-info">
                  <button
                    onClick={() => waybillPrint(object)}
                    className="rounded-md bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 transition-colors duration-300 ease-in-out focus:outline-none"
                  >
                    WayBill
                  </button>
                </td>
                <td className="pr-10 print-more-info">
                  <button
                    onClick={() => recieptPrint(object)}
                    className="rounded-md bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 transition-colors duration-300 ease-in-out focus:outline-none"
                  >
                    Receipt
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-4 text-xs font-semibold md:flex md:justify-between">
        <div className="opacity-60 mb-2 md:mb-0 md:mr-4">
          {listTransaction.length > 0
            ? `Showing transactions ${startIndex + 1} - ${Math.min(
                endIndex,
                listTransaction.length
              )}`
            : "No transactions found"}
        </div>
        <div className="flex items-center">
          <span className="hidden md:inline">Page:</span>
          <span className="flex gap-2 ml-2">
            {Array.from({ length: Math.ceil(listTransaction.length / 10) }).map(
              (_, index) => (
                <span
                  key={index}
                  className={`${
                    currentPage === index + 1
                      ? "bg-[#127ec8] text-white rounded-full"
                      : ""
                  } w-6 h-6 grid place-content-center cursor-pointer`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </span>
              )
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default History;
