import React from 'react'
import NavCard from '../../Components/navCard'
import airtimeimg from "../../images/Airtime.png"
import dataimg from "../../images/Data.png"
import Back from '../../Components/Back'
import RecentTransactions from '../../Components/recentTransactions'
// import RechargeBody from '../../Components/Recharge/RechargeBody'
import StoreAd from '../../Components/storeAdvert'
import { Link } from 'react-router-dom'


export default function Recharge() {
  return (
    <div>
      <div className='flex items-center text-sm font-bold gap-4 border-b pb-4'>
        <Back />
        Airtime and Data.
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5'>
         <div className='lg:col-span-2'>
           <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mb-5'>
             <Link to='/airtime'>
               <NavCard  serviceImg = {airtimeimg} serviceName = "Buy Airtime" />
             </Link>
             <Link to='/data'>
             <NavCard  serviceImg = {dataimg} serviceName = "Buy Data" />
             </Link>
           </div> 
           <StoreAd />
         </div>
         <div>
          <RecentTransactions />
         </div>
      </div>
    </div>
  )
}
