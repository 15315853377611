import React from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from "react-lazy-load-image-component";
function PageCard({img, name, link}) {
  return (
    <Link to={link}>
        {/* <div className=' p-3 rounded-full border-gray-400 shadow flex'>
            <img src={img} width='25' height='20' alt='img'/>
            <p className=' pl-3 pt-1 text-xs'>{name}</p>
        </div> */}
        <div className='grid justify-items-center'>
          <div className='p-3 rounded-full'>
            <LazyLoadImage src={img} alt='img'/>
          </div>
          <p className=' text-center pt-1 text-xs'>{name}</p>
        </div>
    </Link>
  )
}

export default PageCard