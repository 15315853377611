import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import {
  myDetails,
} from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../SelectValue/Loading";

function EditProfile() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const users = useSelector((state) => state.misc);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(
        myDetails({ customer_id: phoneNumber })
      );
      setDetails(payload["result"][0]);
    };
    fetchData();
  }, [dispatch, phoneNumber]);
  const initialValues = {
    company: details.company || "", // Initialize with details.company if available
    address: details.address || "",
    email: details.email || "",
    phonenumber: details.phonenumber || "",
  };



  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        //validationSchema={validationSchema}
        //onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                type="text"
                label="Company:"
                name="company"
                value={values.company}
                onChange={handleChange}
                // Disabled for company field
                disabled
              />
              <FormikControl
                control="input"
                value={details.email}
                type="text"
                label="Email:"
                name="email"
              />
              <FormikControl
                control="input"
                value={details.phonenumber}
                type="text"
                label="Phone Number:"
                name="phonenumber"
              />

              <FormikControl
                control="input"
                type="text"
                value={details.address}
                label="Address:"
                name="address"
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditProfile;
