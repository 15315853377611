import React from 'react';
import { Field, ErrorMessage, useField, useFormikContext } from 'formik';
import Select from 'react-select';
import TextError from './TextError';
import CustomSelect from './customSelect';

const RemitaFormikControl = (props) => {
  const { control, label, name, options, ...rest } = props;

  switch (control) {
    case 'input':
      return (
        <div className="mb-3">
          <label className="text-pri-text block" htmlFor={name}>{label}</label>
          <Field
            id={name}
            className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            name={name}
            {...rest}
          />
          <ErrorMessage name={name} component={TextError} />
        </div>
      );

    case 'textarea':
      return (
        <div className="mb-3">
          <label className="text-pri-text block" htmlFor={name}>{label}</label>
          <Field
            as="textarea"
            id={name}
            className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            name={name}
            {...rest}
          />
          <ErrorMessage name={name} component={TextError} />
        </div>
      );

    case 'select':
      return (
        <div className="mb-3">
          <label className="text-pri-text block" htmlFor={name}>{label}</label>
          <Field
            as="select"
            id={name}
            className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            name={name}
            {...rest}
          >
            <option value="">Select an option</option>
            {options.map(option => (
              <option key={option.VALUE} value={option.VALUE}>{option.DISPLAY_NAME}</option>
            ))}
          </Field>
          <ErrorMessage name={name} component={TextError} />
        </div>
      );

    //   <Select
//     defaultValue={[colourOptions[2], colourOptions[3]]}
//     isMulti
//     name="colors"
//     options={colourOptions}
//     className="basic-multi-select"
//     classNamePrefix="select"
//   />
    case 'multiselect':
      return <CustomSelect label={label} name={name} options={options}  {...rest} />;

    case 'date':
      return (
        <div className="mb-3">
          <label className="text-pri-text block" htmlFor={name}>{label}</label>
          <Field
            type="date"
            id={name}
            className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
            name={name}
            {...rest}
          />
          <ErrorMessage name={name} component={TextError} />
        </div>
      );

    default:
      return null;
  }
};

export default RemitaFormikControl;
