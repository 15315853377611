import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Input (props) {
  const { label, name, ...rest } = props
  return (
    <div className='mb-3'>
      <label className=' text-pri-text block' htmlFor={name}>{label}</label>
      <Field id={name} className='mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3' name={name} {...rest} />
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}


export default Input
