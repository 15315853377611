import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import Back from "../Back";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import { signUp } from "../../data/local/reducers/user.reducer";
import Loading from "../SelectValue/Loading";

const validationSchema = Yup.object({
  phoneNumber: Yup.string().required("Required"),
  confirmPhoneNumber: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  securityQuestion: Yup.string().required("Required"),
  securityAnswer: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});
function RegForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const verifyBVNProfile = useSelector((state) => state.user.verifyBvnProfile);
  const fetchQuestions = useSelector(
    (state) => state.user.fetchSecurityQuestionProfile
  );

  const securityOptions = fetchQuestions.result;

  const genderOptions = [
    { key: "Select an option", value: "" },
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
  ];

  const referralTypeOptions = [
    { key: "Select an option", value: "" },
    { key: "Referral Phonenumber", value: "referral_phonenumber" },
    { key: "Aggregator Code", value: "aggregator_code" },
  ];

  const initialValues = {
    phoneNumber: verifyBVNProfile["personal_info"].phone_number ?? "",
    confirmPhoneNumber: "",
    firstName: verifyBVNProfile["personal_info"].first_name ?? "",
    lastName: verifyBVNProfile["personal_info"].last_name ?? "",
    email: verifyBVNProfile["personal_info"].email ?? "akinyeleroqeeb@gmail.com",
    gender: "",
    securityQuestion: "",
    securityAnswer: "",
    password: "",
    referralPhoneNumber: "",
    aggregatorCode: "",
    referral: "",
  };

  const onSubmit = async (values) => {
    let userCredential = {
      gender: values.gender,
      phonenumber: values.phoneNumber,
      secure_id: values.securityQuestion,
      pin: values.password,
      email: values.email,
      lastname: values.lastName,
      bvn: verifyBVNProfile.bvn_number ?? "",
      promo_code: "0",
      firstname: values.firstName,
      answer: values.securityAnswer,
      bvn_phonenumber: values.phoneNumber,
      referral_phonenumber: values.referralPhoneNumberController ?? "",
      aggregator_code: values.aggregatorCodeController ?? "",
      agreed: 0,
      transaction_id: generateTransactionId(),
      channel: "web",
    };
    const { payload } = await dispatch(signUp(userCredential));

    if (payload.status_code === "0") {
      navigate("/otp");
    }
  };
  return (
    <div className="mx-4 md:mx-16 h-screen grid content-center text-sm">
      <Loading open={users.loading} />
      <div className="bg-white rounded-md px-8 md:px-10 py-16">
        <div className="flex justify-between items-center">
          <Back />
          <Link to="/login">
            <p className=" text-button-color font-bold">Sign in</p>
          </Link>
        </div>

        <div className="pt-8">
          <p className="text-pri-text font-bold">Register with BVN</p>
          <p className="opacity-50">Confirm your personal details</p>
        </div>

        <div className="py-8 pr-2 overflow-y-auto h-80 md:h-64 my-5">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, handleChange, touched }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="tel"
                  label="Phone number"
                  name="phoneNumber"
                  values={values.phoneNumber}
                  onChange={handleChange}
                  disabled={true}
                  error={touched.phoneNumber && errors.phoneNumber}
                />
                <FormikControl
                  control="input"
                  type="tell"
                  label="Confirm phone number"
                  name="confirmPhoneNumber"
                  values={values.confirmPhoneNumber}
                  onChange={handleChange}
                  error={
                    touched.confirmPhoneNumber && errors.confirmPhoneNumber
                  }
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="First name"
                  name="firstName"
                  values={values.firstName}
                  onChange={handleChange}
                  disabled={true}
                  error={touched.firstName && errors.firstName}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Last name"
                  name="lastName"
                  values={values.lastName}
                  onChange={handleChange}
                  disabled={true}
                  error={touched.lastName && errors.lastName}
                />
                <FormikControl
                  control="input"
                  type="email"
                  label="Email address"
                  name="email"
                  values={values.email}
                  onChange={handleChange}
                  disabled={true}
                  error={touched.email && errors.email}
                />
                <FormikControl
                  control="select"
                  options={genderOptions}
                  label="Gender"
                  name="gender"
                  values={values.gender}
                  onChange={handleChange}
                  error={touched.gender && errors.gender}
                />

                <FormikControl
                  control="selected"
                  options={securityOptions}
                  label="Security question"
                  name="securityQuestion"
                  values={values.securityQuestion}
                  onChange={handleChange}
                  error={touched.securityQuestion && errors.securityQuestion}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Security answer"
                  name="securityAnswer"
                  values={values.securityAnswer}
                  onChange={handleChange}
                  error={touched.securityAnswer && errors.securityAnswer}
                />
                <FormikControl
                  control="select"
                  options={referralTypeOptions}
                  label="Referral Type"
                  name="referral"
                  values={values.referral}
                  onChange={handleChange}
                  error={touched.referral && errors.referral}
                />

                {values.referral === "referral_phonenumber" && (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Referral Phonenumber"
                    name="referralPhoneNumber"
                    values={values.referralPhoneNumber}
                    onChange={handleChange}
                    error={
                      touched.referralPhoneNumber && errors.referralPhoneNumber
                    }
                  />
                )}
                {values.referral === "aggregator_code" && (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Aggregator Code"
                    name="aggregatorCode"
                    values={values.aggregatorCode}
                    onChange={handleChange}
                    error={touched.aggregatorCode && errors.aggregatorCode}
                  />
                )}

                <FormikControl
                  control="password"
                  label="Create transaction PIN"
                  name="password"
                  values={values.password}
                  onChange={handleChange}
                  error={touched.password && errors.password}
                />

                <div className=" grid grid-cols-1 mb-5 mt-6">
                  <Button name="Verify" />
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {/* <div>
          <p className="text-button-color"> I don’t have a BVN</p>
        </div> */}
      </div>
    </div>
  );
}

export default RegForm;
