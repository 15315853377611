import React from 'react'

function FeatureCard({featureIcon, featureTitle, featureBody, featureImage, container}) {
    return (
           <div className={container}>
             <img src={featureImage} alt="" className='w-full p-1 gap-3 rounded-lg h-52 object-cover'/>
             <div className='p-5'>
               <img src={featureIcon} alt="" className='w-5 h-5'/>
               <p className='mt-4 mb-2 font-bold'>{featureTitle}</p>
               <p className='text-sm lg:h-20 mb-4 lg:mb-0'>{featureBody}</p>
             </div>
           </div>
    )
  }
    
export default FeatureCard