import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { useDispatch, useSelector } from "react-redux";
import {
  cableTvProduct,
  cableTvAddon,
  cardValidation,
  cableTvSubscription,
  cableBeneficiary,
  saveCableBeneficiaries,
} from "../../data/local/reducers/miscellaneous.reducer";
import Loading from "../SelectValue/Loading";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";

function DstvPlatinumForm(props) {
  const [value, setValue] = useState(false);
  const handleClose = () => setValue(false);
  const dispatch = useDispatch();
  const [cardValidations, setCardValidations] = useState([]);
  const [product, setProduct] = useState([]);
  const [addonsValue, setAddonsValue] = useState([]);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const users = useSelector((state) => state.misc);
  const [selectedProducts, setSelectedProducts] = useState("");
  const [selectedAddon, setSelectedAddon] = useState("");
  const [formValues, setFormValues] = useState(null);
  const { serviceCode, operatorCode } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [savedbeneficiary, setSavedBeneficiary] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
    if (showDropdown) {
      setSelectedBeneficiary(""); // Reset selected beneficiary when hiding the dropdown
    }
    if (!showDropdown) {
      cableBeneficiaries();
    }
  };

  const cableBeneficiaries = async () => {
    const { payload } = await dispatch(
      cableBeneficiary({
        phonenumber: phoneNumber,
        card_type: serviceCode,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    const { result } = payload;
    setBeneficiary(result);
  };

  const cableBenefit = beneficiary ? [...beneficiary] : [];
  useEffect(() => {
    if (serviceCode !== "startimes") {
      const fetchData = async () => {
        const provider = serviceCode;
        const { payload } = await dispatch(cableTvProduct(provider));
        setProduct(payload?.result["statusDescription"]["items"]);
      };
      fetchData();
    }
  }, [dispatch, serviceCode]);

  const verifyMeterNumber = async (meterNumber) => {
    if (
      serviceCode === "startimes"
        ? meterNumber?.length === 11
        : meterNumber?.length === 10
    ) {
      const { payload } = await dispatch(
        cardValidation({
          cable_service_code: serviceCode,
          cable_operator_code: operatorCode,
          card_identity: meterNumber,
          token: token,
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
        })
      );
      //"1031370256"

      if (payload?.message) {
        const { message } = payload;
        serviceCode === "startimes"
          ? setCardValidations(message)
          : setCardValidations(message["statusDescription"]);
        //setCardValidations(message["statusDescription"]);
      } else {
        showErrorToast("Service Not Available");
      }
    }
  };

  const handleProductChange = (value) => {
    setSelectedProducts(value);
  };

  const handleAddonChange = (value) => {
    setSelectedAddon(value);
  };

  const verifyAddons = async (product) => {
    const initialValues = {
      product_code: product.split("|")[2],
      cable_service_code: serviceCode,
    };
    const { payload } = await dispatch(
      cableTvAddon({
        initialValues,
      })
    );

    const { result } = payload;
    setAddonsValue(result["statusDescription"]["items"]);
  };

  const products = product ? [...product] : [];

  const addons = addonsValue ? [...addonsValue] : [];

  const parsedSelectedProducts = parseInt(selectedProducts.split("|")[0]);
  const parsedSelectedAddon = parseInt(selectedAddon.split("|")[0]);

  const totalAmount =
    !isNaN(parsedSelectedProducts) && !isNaN(parsedSelectedAddon)
      ? parsedSelectedProducts + parsedSelectedAddon
      : !isNaN(parsedSelectedProducts)
      ? parsedSelectedProducts
      : 0;

  const initialValues = {
    cardId: "",
    customerName: "",
    customerNumber: "",
    product: "",
    addon: "",
    productcode: ".",
    packagename: ".",
    duration: "",
    totalAmount: "",
    subscription: "",
    amount: "",
  };

  const validationSchema = Yup.object({
    cardId: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    resetForm();
  };
  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values);
    setValue(true);
    const { cardId, amount } = formValues; // Store the form values in the state
    if (savedbeneficiary) {
      const { payload } = await dispatch(
        saveCableBeneficiaries({
          phonenumber: phoneNumber,
          beneficiary_name:
            serviceCode === "startimes"
              ? cardValidations?.customerName
              : cardValidations?.firstname,
          card_type: serviceCode,
          card_identity: cardId,
          customer_number:
            serviceCode === "startimes"
              ? cardValidations?.customerNo
              : cardValidations?.customerNo,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
    } // Store the form values in the state
    const formData = {
      card_identity: cardId,
      cable_service_code: serviceCode,
      cable_operator_code: operatorCode,
      customer_name:
        serviceCode === "startimes"
          ? cardValidations?.customerName
          : cardValidations?.firstname,
      customer_no:
        serviceCode === "startimes"
          ? cardValidations?.customerNo
          : cardValidations?.customerNo,
      addon_amount: selectedAddon.split("|")[0],
      addon_product_code: selectedAddon.split("|")[2],
      addon_product_name: selectedAddon.split("|")[3],
      product_amount: 20, // selectedProduct("|")[0],
      has_addon: selectedAddon === "" ? "0" : "1",
      invoice_period: selectedProducts.split("|")[1],
      package_name: selectedProducts.split("|")[3],
      product_codes: selectedProducts.split("|")[2],
      amount: serviceCode === "startimes" ? amount : totalAmount,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      phonenumber: data.phonenumber,
    };
    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(cableTvSubscription(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
      <div className="flex justify-end mr-4">
        <button
          type="button"
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 font-semibold px-3 py-2 text-sm rounded shadow"
          onClick={handleDropdownToggle}
        >
          Show Beneficiaries
        </button>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {showDropdown && (
                <FormikControl
                  control="selectCableBeneficiary"
                  options={cableBenefit}
                  label="Select wallet beneficiary:"
                  name="bank_code"
                  value={values.walletBeneficiaries}
                  onChange={(event) => {
                    setSelectedBeneficiary(event.target.value);
                    // Update selected beneficiary value
                  }}
                  error={touched.bank_code && errors.bank_code}
                />
              )}
              <FormikControl
                control="input"
                type="integer"
                label="Smart Card Number:"
                name="cardId"
                value={
                  showDropdown
                    ? selectedBeneficiary.split("|")[1]
                    : values.cardId
                }
                onChange={(event) => {
                  handleChange(event);
                  verifyMeterNumber(event.target.value);
                }}
                error={touched.cardId && errors.cardId}
              />

              <FormikControl
                control="input"
                type="text"
                label="Customer name:"
                name="customerName"
                value={
                  serviceCode === "startimes" || showDropdown
                    ? cardValidations?.customerName ??
                      selectedBeneficiary.split("|")[0]
                    : cardValidations?.firstname
                }
                onChange={handleChange}
                disabled={true}
              />

              <FormikControl
                control="input"
                type="integer"
                label="Customer Number:"
                name="customerNumber"
                value={
                  serviceCode === "startimes" || showDropdown
                    ? cardValidations?.customerNo ??
                      selectedBeneficiary.split("|")[2]
                    : cardValidations?.customerNo
                }
                onChange={handleChange}
                disabled={true}
              />
              {serviceCode !== "startimes" ? (
                <FormikControl
                  control="selectCable"
                  options={products}
                  label="Select product:"
                  name="products"
                  value={selectedProducts || values.product}
                  onChange={(event) => {
                    handleChange(event);
                    verifyAddons(event.target.value);
                    handleProductChange(event.target.value);
                  }}
                />
              ) : null}

              {serviceCode === "dstv" ? ( // Using ternary operator for conditional rendering
                <FormikControl
                  control="selectCable"
                  options={addons}
                  label="Available addons: (optional)"
                  name="addon"
                  //value={selectedAddon || values.addon}
                  onChange={(event) => {
                    handleChange(event);
                    handleAddonChange(event.target.value);
                  }}
                />
              ) : null}
              {serviceCode !== "startimes" ? (
                <FormikControl
                  control="input"
                  type="integer"
                  label="Subscription amount:"
                  name="totalAmount"
                  value={totalAmount}
                  disabled
                />
              ) : null}
              {serviceCode === "startimes" ? (
                <FormikControl
                  control="input"
                  type="integer"
                  label="Amount:"
                  name="amount"
                  value={values.amount}
                />
              ) : null}

              {!showDropdown && (
                <div className="px-4 text-sm items-center text-slate-600 mb-10 flex gap-3">
                  <input
                    type="checkbox"
                    name="Save beneficiary"
                    id="save_beneficiary"
                    checked={values.send_message}
                    onChange={() => setSavedBeneficiary(!savedbeneficiary)}
                  />
                  <span>Save as beneficiary</span>
                </div>
              )}
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DstvPlatinumForm;
