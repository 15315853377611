import React from 'react'
import RegisterForm from '../../Components/Signup/RegisterForm'
import Banneroutside from '../../Components/outsidebanner'

function Signup() {
  return (
    <div className='bg-bg-main h-screen'>
       <div className='grid lg:grid-cols-2'>
        <div>
          <RegisterForm />
        </div>
        <Banneroutside />
       </div>
    </div>
  )
}

export default Signup