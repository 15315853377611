import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import Back from "../Back";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../SelectValue/Loading";
import { sendOTP } from "../../data/local/reducers/user.reducer";

const validationSchema = Yup.object({
  phonenumber: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(11, "Must be exactly 11 digits")
    .max(11, "Must be exactly 11 digits"),
});
function RegisterNoBvnForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);

  const handleSubmit = async (values) => {
    const { email, phonenumber } = values;
    let userCredential = {
      email,
      phonenumber,
    };
    const payload  = dispatch(sendOTP(userCredential));
  };

  return (
    <div className="mx-4 md:mx-16 h-screen grid content-center text-sm">
      <Loading open={users.loading} />
      <div className="bg-white rounded-md px-8 md:px-10 py-16">
        <div className="flex justify-between items-center">
          <Back />
          <Link to="/login">
            <p className=" text-button-color font-bold">Sign in</p>
          </Link>
        </div>

        <div className="pt-8">
          <p className="text-pri-text font-bold">Register without BVN</p>
        </div>

        <div className="py-8">
          <Formik
            initialValues={{
              email: "",
              phonenumber: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, handleChange, touched }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="tel"
                  label="Phone number"
                  name="phonenumber"
                  values={values.phonenumber}
                  onChange={handleChange}
                  error={touched.phonenumber && errors.phonenumber}
                />
                <FormikControl
                  control="input"
                  type="email"
                  label="Email address"
                  name="email"
                  values={values.email}
                  onChange={handleChange}
                  error={touched.email && errors.email}
                />
                <div className=" grid grid-cols-1 mt-6">
                  <Button name="Verify" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          <Link to="/register">
            <p className="text-button-color"> I have a BVN</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RegisterNoBvnForm;
