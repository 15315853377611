import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { useSelector, useDispatch } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import {
  generateToken,
  resetSecurityDetails,
} from "../../data/local/reducers/user.reducer";
import { showSuccessToast } from "../../utils/api-utils";

function ChangeSecurityAnswerForm() {
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const email = loginProfile.user_detail[0].email;
  const token = useSelector((state) => state.user.loginProfile.token);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const [verifyStatus, setVerifyStatus] = useState(true);

  const verifyPinValue = async (event) => {
    const passcode = event.target.value;
    const { payload } = await dispatch(
      generateToken({
        passcode: passcode,
        operation_type: "PIN",
        email: email,
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        user_type: data.user_type,
        channel: "web",
        token: token,
      })
    );

    setVerifyStatus(payload.status_code);
    if (payload.status_code === "0") {
      showSuccessToast(payload.message);
    }
  };

  const initialValues = {
    pin: "",
    securityToken: "",
  };

  const validationSchema = Yup.object({
    pin: Yup.string().required("Required"),
    securityToken: Yup.string().required("securityToken"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const { pin, securityToken } = values;
    const formData = {
      security_token: securityToken,
      pin: pin,
      update_type: "PIN",
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      phonenumber: phoneNumber,
    };

    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(resetSecurityDetails(formData))
        : "";

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            {verifyStatus !== "0" ? (
              <>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormikControl
                    control="input"
                    value={values.passcode}
                    type="text"
                    label="Enter Security Answer:"
                    name="passcode"
                    //onChange={verifyPinValue}
                    onBlur={verifyPinValue}
                  />
                </div>
                <div className="px-4 grid grid-cols-1 md:grid-cols-2">
                  <Button name="Continue" onClick={verifyPinValue} />
                </div>
              </>
            ) : (
              <>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormikControl
                    control="input"
                    type="text"
                    label="Enter Pin"
                    name="pin"
                    value={values.pin}
                    onChange={handleChange}
                    error={touched.pin && errors.pin}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Security Token"
                    name="securityToken"
                    value={values.securityToken}
                    onChange={handleChange}
                    error={touched.securityToken && errors.securityToken}
                  />
                </div>
                <div className="px-4 grid grid-cols-1 md:grid-cols-2">
                  <Button name="Continue" />
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangeSecurityAnswerForm;
