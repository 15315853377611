import React from 'react'
import Transaction from "./transaction"
import {Link} from 'react-router-dom';

function RecentTransactions() {
  return (
    <div className='rounded-lg border text-sm'>
      <div className='flex justify-between p-4 items-center border-b'>
        <p className='font-bold'>Recent transactions</p>
        <Link to='/transactionlist'>
        <p className='opacity-40 hover:text-[#127EC8] hover:opacity-80 cursor-pointer'>View all👁️</p>
        </Link>
      </div>
        <Transaction />
    </div>
  )
}

export default RecentTransactions