import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { useSelector, useDispatch } from "react-redux";
import {
  estateMeterValidation,
  estateElectricity,
} from "../../data/local/reducers/miscellaneous.reducer";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { showSuccessToast } from "../../utils/api-utils";

function PrivateElectricityForm(props) {
  const [value, setValue] = useState(false);
  const [meterNumbers, setMeterNumbers] = useState("");
  const handleClose = () => setValue(false);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const [recipientName, setRecipientName] = useState("");
  const [formValues, setFormValues] = useState(null);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);

  const handleMeterNumberValidation = async (event) => {
    const meterNumber = event.target.value;
    setMeterNumbers(meterNumber);
    if (meterNumber.length === 13) {
      const { payload } = await dispatch(
        estateMeterValidation({
          meter_number: meterNumber,
        })
      );
      const { result } = payload;
      setRecipientName(result);
    }
  };

  const initialValues = {
    amount: "",
  };

  const validationSchema = Yup.object({
    amount: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    resetForm();
  };

  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    const formData = {
      ...formValues,
      card_identity: meterNumbers,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      estate_code: props.dataReceived,
      customer_name: recipientName.CustomerName,
      order_number: recipientName.reqId,
      time_stamp: recipientName.timestamp,
      channel: "web",
      user_type: data.user_type,
      token: token,
      operation_summary: "wallet transfer",
      phonenumber: data.phonenumber,
      loading_type: '0'
    };

    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(estateElectricity(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      showSuccessToast(payload.message);
      resetForm();
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.meter_number}
                type="text"
                label="Meter Number:"
                name="meter_number"
                onChange={handleMeterNumberValidation}
              />
              <FormikControl
                control="input"
                type="text"
                label="Customer name"
                name="recipient_name"
                value={recipientName.CustomerName}
              />

              <FormikControl
                control="input"
                value={values.amount}
                type="integer"
                label="Amount:"
                name="amount"
                error={touched.phoneNumber && errors.phoneNumber}
              />
            </div>

            {/* <div className="px-4 text-sm items-center text-slate-600 mb-10 flex gap-3">
              <input
                type="checkbox"
                name="Save beneficiary"
                id="save_beneficiary"
              />
              <span>Save as beneficiary</span>
            </div> */}

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PrivateElectricityForm;
