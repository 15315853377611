import React from "react";
import NavCard from "../../Components/navCard";
import dstv from "../../images/DSTV imgg.svg";
import gotv from "../../images/GOTV img.svg";
import startimes from "../../images/Startimes img.svg";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import { Link } from "react-router-dom";
import {
  DSTV_SERVICE_CODE,
  DSTV_OPERATOR_CODE,
  GOTV_OPERATOR_CODE,
  GOTV_SERVICE_CODE,
  STARTIMES_OPERATOR_CODE,
  STARTIMES_SERVICE_CODE,
} from "../../utils/constant";

function SelectPlatinum() {
  return (
    <div>
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        TV Subscription.
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <Link
              to={`/dstvPlatinum?SERVICE_CODE=${DSTV_SERVICE_CODE}&OPERATOR_CODE=${DSTV_OPERATOR_CODE}`}
            >
              <NavCard serviceImg={dstv} serviceName="DSTV" />
            </Link>

            <Link
              to={`/dstvPlatinum?SERVICE_CODE=${GOTV_SERVICE_CODE}&OPERATOR_CODE=${GOTV_OPERATOR_CODE}`}
            >
              <NavCard serviceImg={gotv} serviceName="GoTV" />
            </Link>

            <Link
              to={`/dstvPlatinum?SERVICE_CODE=${STARTIMES_SERVICE_CODE}&OPERATOR_CODE=${STARTIMES_OPERATOR_CODE}`}
            >
              <NavCard serviceImg={startimes} serviceName="Startimes" />
            </Link>
          </div>
        </div>
        <div className="grid gap-5">
          <StoreAd />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default SelectPlatinum;
