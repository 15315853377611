import { useState } from "react";
import {
  AmountFormatter,
  FormattedDate,
  ProvidusReceipt,
  TransferReceipt,
  airtimeReceipt,
  cableReceipt,
  examReceipt,
  receipt,
  remitasReceipt,
  transactionReceipt,
  walletReceipt,
} from "../utils/constant";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "./SelectValue/Button";
import FormikControl from "./SelectValue/FormikControl";
import debiticon from "../images/debit icon.svg";
import crediticon from "../images/credit icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import { exportToExcel } from "../utils/exportUtils";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  fetchFullTransactionList,
  fetchWalletHistory,
} from "../data/local/reducers/user.reducer";
import Loading from "./SelectValue/Loading";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { format } from "date-fns";
import { showSuccessToast } from "../utils/api-utils";

const TransactionTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedArrivalDateTime, setSelectedArrivalDateTime] = useState(
    new Date()
  );
  const [selectedDepartureDateTime, setSelectedDepartureDateTime] = useState(
    new Date()
  );
  const [transactionList, setTransactionList] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const loginProfile = useSelector((state) => state.user.loginProfile);
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const accountNumber = loginProfile.user_detail[0].account_no;
  const fullName =
    loginProfile.user_detail[0].firstname +
    loginProfile.user_detail[0].lastname;
  const users = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  //   // Search transaction
  const [searchText, setSearchText] = useState("");
  //   const handlePageChange = (page) => {
  //     setCurrentPage(page);
  //   };
  const handleArrivalDateTimeChange = (newDateTime) => {
    setSelectedArrivalDateTime(newDateTime);
  };

  const handleDepartureDateTimeChange = (newDateTime) => {
    setSelectedDepartureDateTime(newDateTime);
  };

  const operationType = [
    { key: "Select an option", value: "" },
    { key: "Debit", value: "DR" },
    { key: "Credit", value: "CR" },
  ];

  const initialValues = {
    eventDateTime: new Date(),
    operationType: "",
  };

  const handleSinglePrint = async (Content) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(Content);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 300);
  };

  const validationSchema = Yup.object({});

  const onSubmit = async (values, { resetForm }) => {
    const { operationType } = values;

    const arrivalDate = selectedArrivalDateTime.$d;
    const formattedArrivalDate = format(arrivalDate, "yyyy-MM-dd");
    const departureDate = selectedDepartureDateTime.$d;
    const formattedDepartureDate = format(departureDate, "yyyy-MM-dd");

    const formData = {
      operation_type: operationType,
      start_date: formattedArrivalDate,
      end_date: formattedDepartureDate,
      phonenumber: phoneNumber,
    };

    const handleSinglePrint = async (Content) => {
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(Content);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
      }, 300);
    };
    let total_credit_balance = 0;
    let total_debit_balance = 0;
    const { payload } = await dispatch(fetchWalletHistory(formData));
    if (payload.status_code === "0") {
      showSuccessToast(payload.message);
      setTransactionList(payload.result);
      setShowTable(true);
      payload.result.forEach((value) => {
        let amount = parseFloat(value.amount); // Convert amount string to float
        if (value.operation_type === "CR") {
          total_credit_balance += amount;
        } else if (value.operation_type === "DR") {
          total_debit_balance += amount;
        }
      });
      const firstWalletBalance = parseFloat(payload.result[0].wallet_balance);
      const lastWalletBalance = parseFloat(
        payload.result[payload.result.length - 1].wallet_balance
      );
      const itemsHtml = payload.result
        .map(
          (item, index) =>
            `<tr id="row-${index + 1}" style="border: 1px solid #ccc;">
                <td style="border: 1px solid #ccc; padding: 8px 12px;">${
                  index + 1
                }</td>
                                <td style="border: 1px solid #ccc; padding: 8px 12px;">${
                  item.operation_at
                }</td>
                <td style="border: 1px solid #ccc; padding: 8px 12px;">${
                  item.service_account_name
                }</td>
                <td style="border: 1px solid #ccc; padding: 8px 12px;">${
                  item.operation_summary
                }</td>
                <td style="border: 1px solid #ccc; padding: 8px 12px;">${
                  item.operation_type
                }</td>
                <td style="border: 1px solid #ccc; padding: 8px 12px;">${AmountFormatter(
                  {
                    amount: item.amount,
                  }
                )}</td>
                <td style="border: 1px solid #ccc; padding: 8px 12px;">${AmountFormatter(
                  {
                    amount: item.wallet_balance,
                  }
                )}</td>
                <td style="border: 1px solid #ccc; padding: 8px 12px;">${
                  item.status
                }</td>

            </tr>`
        )
        .join("");

      const tableHtml = `
        <thead style="background-color: #184767; color: #fff;">
            <tr style="border: 1px solid #ccc;">
                <th style="border: 1px solid #ccc; padding: 8px 12px;">ID</th>
                <th style="border: 1px solid #ccc; padding: 8px 12px;">Operation Time</th>
                <th style="border: 1px solid #ccc; padding: 8px 12px;">Service Account Name</th>
                <th style="border: 1px solid #ccc; padding: 8px 12px;">Summary</th>
                <th style="border: 1px solid #ccc; padding: 8px 12px;">Operation Type</th>
                <th style="border: 1px solid #ccc; padding: 8px 12px;">Amount</th>
                <th style="border: 1px solid #ccc; padding: 8px 12px;">Wallet Balance</th>
                <th style="border: 1px solid #ccc; padding: 8px 12px;">Status</th>
                
            </tr>
        </thead>
        <tbody>
            ${itemsHtml}
        </tbody>
    `;

      const tableContent = `
        <table id="transactionTable" style="font-size: 14px; width: 100%; border-collapse: collapse;">
            ${tableHtml}
        </table>
    `;

      const htmlSingleContent = transactionReceipt(
        payload.result,
        phoneNumber,
        accountNumber,
        formattedArrivalDate,
        formattedDepartureDate,
        AmountFormatter({ amount: total_credit_balance, currency: "NGN" }),
        AmountFormatter({ amount: total_debit_balance, currency: "NGN" }),
        AmountFormatter({ amount: firstWalletBalance, currency: "NGN" }),
        AmountFormatter({ amount: lastWalletBalance, currency: "NGN" }),
        tableContent
      );
      handleSinglePrint(htmlSingleContent);
    }
  };

  const limitedTransactions = transactionList.slice(startIndex, endIndex);

  const csvData = transactionList.map((transaction) => ({
    Description: transaction.service_account_name,
    "Date / time": transaction.operation_at,
    Amount: transaction.amount,
    "Reference ID": transaction.reference_id,
  }));

  const handleExportExcel = () => {
    exportToExcel(transactionList);
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableData = transactionList.map((transaction) => [
      transaction.service_account_name,
      transaction.operation_at,
      transaction.operation_summary,
      transaction.amount,
      transaction.reference_id,
    ]);
    doc.autoTable({
      head: [
        ["Description", "Date / time", "Summary", "Amount", "Reference ID"],
      ],
      body: tableData,
    });
    doc.save("transactions.pdf");
  };

  const singlePrint = async (object) => {
    const formData = {
      phonenumber: phoneNumber,
      reference_id: object.reference_id,
    };
    const { payload } = await dispatch(fetchFullTransactionList(formData));
    if (payload.status_code === "0") {
      const serviceCode = payload["customer_wallet_result"].service_account_no;
      let htmlSingleContent;
      switch (serviceCode) {
        case "1000000012":
        case "1000000031":
        case "1000000026":
          htmlSingleContent = receipt(payload, accountNumber, fullName);
          break;
        case "1000000013":
        case "1000000014":
        case "1000000015":
          htmlSingleContent = cableReceipt(payload, accountNumber, fullName);
          break;
        case "1000000017":
          htmlSingleContent = TransferReceipt(payload, accountNumber, fullName);
          break;
        case "1000000001":
        case "1000000002":
        case "1000000003":
        case "1000000004":
        case "1000000005":
        case "1000000006":
        case "1000000007":
        case "1000000008":
          htmlSingleContent = airtimeReceipt(payload, accountNumber, fullName);
          break;
        case "1000000023":
          htmlSingleContent = ProvidusReceipt(payload, accountNumber, fullName);
          break;
        case "1000000021":
          htmlSingleContent = walletReceipt(payload, accountNumber, fullName);
          break;
        case "1000000081":
        case "1000000080":
          htmlSingleContent = examReceipt(payload, accountNumber, fullName);
          break;
        case "1000000083":
          htmlSingleContent = remitasReceipt(payload, accountNumber, fullName);
          break;
        default:
          htmlSingleContent = airtimeReceipt(payload, accountNumber, fullName);
          break;
      }
      handleSinglePrint(htmlSingleContent);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="border rounded-md">
      <Loading open={users.loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2  gap-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    key="arrival-time-picker"
                    label="Start Date"
                    values={selectedArrivalDateTime}
                    onChange={handleArrivalDateTimeChange}
                    className="border w-full text-center"
                  />
                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    key="departure-time-picker"
                    label="End Date"
                    values={selectedDepartureDateTime}
                    onChange={handleDepartureDateTimeChange}
                    className="border w-full text-center"
                  />
                </DemoContainer>
              </LocalizationProvider>

              <FormikControl
                control="select"
                options={operationType}
                name="operationType"
                values={values.operationType}
                onChange={handleChange}
                error={touched.operationType && errors.operationType}
              />
            </div>

            <div className="px-4 mt-4 md:mt-0 grid grid-cols-1 md:grid-cols-2">
              <Button type="submit" name="Continue" />
            </div>
          </Form>
        )}
      </Formik>

      {/* Search and Export Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border-b p-4 items-center">
        {/* Search */}
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Transaction..."
            className="block p-2 pl-10 text-sm text-black border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* Export */}
        <div className="text-xs flex gap-4 justify-center md:justify-end">
          <span className=" font-semibold">Export:</span>
          <div className="flex opacity-80 font-medium">
            <span
              className="pr-2 hover:text-[#127EC8] cursor-pointer"
              onClick={handleExportExcel}
            >
              Excel
            </span>
            <CSVLink
              data={csvData}
              filename={"transactions.csv"}
              className="px-2 hover:text-[#127EC8] cursor-pointer"
            >
              CSV
            </CSVLink>
            <span
              className="px-2 hover:text-[#127EC8] cursor-pointer"
              onClick={handleExportPDF}
            >
              PDF
            </span>
            <span
              className="pl-2 hover:text-[#127EC8] cursor-pointer"
              //   onClick={handlePrint}
            >
              Print
            </span>
          </div>
        </div>
      </div>

      {/* Transaction Table */}
      {showTable && (
        <div className="rounded-t-md max-w-full overflow-x-scroll m-4 print-table">
          <table className="w-full text-sm text-left">
            <thead className="text-[#127EC8] bg-[#127EC830]">
              <tr>
                <th className="py-6"></th>
                <th className="px-6">Description</th>
                <th className="px-6">Date / time</th>
                <th className="px-6">Amount</th>
                <th className="px-6">Reference ID</th>
                <th className="px-6 text-center">Download</th>
              </tr>
            </thead>
            <tbody className="">
              {limitedTransactions.map((object, index) => (
                <tr
                  className="bg-white border-b text-black text-[13px]"
                  key={index}
                >
                  <th scope="row" className="sticky left-0 z-10 bg-white">
                    {object &&
                    object.status &&
                    object.status.toLowerCase() === "successful" ? (
                      <div className="w-8 self-center">
                        <img src={crediticon} alt="" />
                      </div>
                    ) : (
                      <div className="self-center w-8">
                        <img src={debiticon} alt="" />
                      </div>
                    )}
                  </th>
                  <td className="px-6 py-4 font-medium whitespace-nowrap">
                    {object.service_account_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {object.operation_at}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    ₦{object.amount}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {object.reference_id}
                  </td>
                  <td className="pr-10 print-more-info mx-auto">
                    <button
                      onClick={() => singlePrint(object)}
                      className="w-4 mt-1 mx-auto"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                          fill="rgba(18,126,200,1)"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="flex flex-col md:flex-row justify-between p-4 text-xs font-semibold">
        <div className="opacity-60 mb-2 md:mb-0">
          {transactionList.length > 0
            ? `Showing transactions ${startIndex + 1} - ${Math.min(
                endIndex,
                transactionList.length
              )}`
            : "No transactions found"}
        </div>
        <div className="flex items-center">
          <span className="mr-2">Page:</span>
          <div className="flex gap-5 ml-2 pagination-container">
            {Array.from({ length: Math.ceil(transactionList.length / 10) }).map(
              (_, index) => (
                <span
                  key={index}
                  className={`${
                    currentPage === index + 1
                      ? "bg-[#127ec8] text-white rounded-full"
                      : ""
                  } w-8 h-8 p-4 mb-4 grid place-content-center cursor-pointer`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </span>
              )
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .pagination-container {
          width: 30rem; /* Adjust this width based on the number of pagination numbers you want to show at a time */
          overflow-x: auto;
          display: flex;
        }
      `}</style>
    </div>
  );
};

export default TransactionTable;
