import React, { useState } from 'react';
import gt_logo from '../../images/gt logo.svg';
import wallet from '../../images/wallet.svg';
import providus_logo from '../../images/providus logo.svg';
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WalletDetails = () => {
  const user = useSelector((state) => state.user.loginProfile);

  const [providusCopy, setProvidusCopy] = useState(false);
  const [gtbankCopy, setGTBankCopy] = useState(false);
  const [accountCopy, setAccountCopy] = useState(false);

  const handleCopyProvidus = (text) => {
    setProvidusCopy(true);
    setGTBankCopy(false);
    setAccountCopy(false)
    toast.success('Providus Bank account number copied!', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleCopyGTBank = (text) => {
    setGTBankCopy(true);
    setProvidusCopy(false);
    setAccountCopy(false)
    toast.success('GTbank account number copied!', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  
  const handleAccountCopy = (text) => {
    setGTBankCopy(false);
    setProvidusCopy(false);
    setAccountCopy(true);
    toast.success('Wallet number copied!', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <div className='text-sm border rounded-lg'>
      <div className='flex items-center justify-between p-4 border-b'>
        <p className='font-bold'>Wallet Details</p>
        <p className='opacity-40 hover:text-[#127EC8] hover:opacity-80 cursor-pointer'>Fund Wallet</p>
      </div>
      <div className='px-2 py-6 grid grid-cols-2 md:grid-cols-3'>
        {/* <div>
          <div className='flex gap-4 items-start'>
            <img src={wallet} alt='' className='h-12 w-12 rounded-lg' />
            <div>
              <p className='text-[15px] font-semibold tracking-normal'>Zippyworld(Wallet number)</p>
              <p className='text-[15px] font-medium pt-2 text-xs tracking-widest opacity-50'>{user.user_detail[0].phonenumber}</p>
              <p className='pt-5 opacity-50 text-[13px]'>
              <CopyToClipboard text={user.user_detail[0].phonenumber} onCopy={handleAccountCopy}>
                  <span style={{ cursor: 'pointer' }}>{accountCopy ? 'Copied!' : 'copy'}</span>
                </CopyToClipboard>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className='flex gap-4 items-start '>
            <img src={providus_logo} alt='' className='h-12 w-12 rounded-lg' />
            <div className=''>
              <p className='text-[15px] font-semibold tracking-normal'>Providus Bank(virtual account)</p>
              <p className='text-[15px] font-medium pt-2 text-xs tracking-widest opacity-50'>{user.user_detail[0].account_no}</p>
              <p className='pt-5 opacity-50 text-[13px]'>
              <CopyToClipboard text={user.user_detail[0].account_no} onCopy={handleCopyProvidus}>
                  <span style={{ cursor: 'pointer' }}>{providusCopy ? 'Copied!' : 'copy'}</span>
                </CopyToClipboard>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className='flex gap-4 items-start '>
            <img src={gt_logo} alt='' className='h-12 w-12 rounded-lg' />
            <div>
              <p className='text-[15px] font-semibold tracking-normal'>GTbank(Virtual Account)</p>
              <p className='text-[15px] font-medium pt-2 text-xs tracking-widest opacity-50'>{user.user_detail[0].gtb_account_no}</p>
              <p className='pt-5 opacity-50 text-[13px]'>
              <CopyToClipboard text={user.user_detail[0].gtb_account_no} onCopy={handleCopyGTBank}>
                  <span style={{ cursor: 'pointer' }}>{gtbankCopy ? 'Copied!' : 'copy'}</span>
                </CopyToClipboard>
              </p>
            </div>
          </div>
        </div> */}
          <div className='grid gap-4'>
           <div className='w-full flex justify-center'><img src={wallet} alt=''/></div>
            <div className='text-center'>
              <p className='text-sm font-semibold tracking-normal'>Zippyworld <span className='opacity-50'>(Wallet number)</span></p>
              <p className='text-xs font-medium tracking-wide mt-2 mb-3 opacity-75'>{user.user_detail[0].phonenumber}</p>
              <p className='opacity-75 text-[13px]'>
              <CopyToClipboard text={user.user_detail[0].phonenumber} onCopy={handleAccountCopy}>
                  <span className=' cursor-pointer text-[#127ec8]'>{accountCopy ? 'Copied!' : 'copy'}</span>
              </CopyToClipboard>
              </p>
            </div>
          </div>
          <div className='grid gap-4'>
           <div className='w-full flex justify-center'><img src={providus_logo} alt=''/></div>
            <div className='text-center'>
              <p className='text-sm font-semibold tracking-normal'>Providus Bank <span className='opacity-50'>(Virtual account)</span></p>
              <p className='text-xs font-medium tracking-wide mt-2 mb-3 opacity-75'>{user.user_detail[0].account_no}</p>
              <p className='opacity-75 text-[13px]'>
              <CopyToClipboard text={user.user_detail[0].account_no} onCopy={handleCopyProvidus}>
                  <span className=' cursor-pointer text-[#127ec8]'>{providusCopy ? 'Copied!' : 'copy'}</span>
              </CopyToClipboard>
              </p>
            </div>
          </div>
          <div className='grid gap-4'>
           <div className='w-full flex justify-center'><img src={gt_logo} alt=''/></div>
            <div className='text-center'>
              <p className='text-sm font-semibold tracking-normal'>GT Bank <span className='opacity-50'>(Virtual account)</span></p>
              <p className='text-xs font-medium tracking-wide mt-2 mb-3 opacity-75'>{user.user_detail[0].gtb_account_no}</p>
              <p className='opacity-75 text-[13px]'>
              <CopyToClipboard text={user.user_detail[0].gtb_account_no} onCopy={handleCopyGTBank}>
                  <span className=' cursor-pointer text-[#127ec8]'>{gtbankCopy ? 'Copied!' : 'copy'}</span>
              </CopyToClipboard>
              </p>
            </div>
          </div>
      </div>
    </div>
  );
};

export default WalletDetails;
