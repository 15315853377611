import React, {useEffect} from 'react'
import { Navigate  } from 'react-router-dom'
import {useDispatch, useSelector}  from "react-redux";
import { logout } from '../../data/local/reducers/user.reducer';

function Logout() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  
  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
}

export default Logout

