import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { airtimePurchase } from "../../data/local/reducers/miscellaneous.reducer";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";

function SoftPOSForm() {
  const [value, setValue] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);

  const handleClose = () => setValue(false);

  const initialValues = {
    firstname: "",
    email: "",
    phonenumber: "",
    altphonenumber: "",
    dateofbirth: "",
    address: "",
    state: "",
    LGA: "",
    amount: "",
  };

  const validationSchema = Yup.object({
    beneficiary_phonenumber: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });
  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    resetForm();
  };
  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    const formData = {
      ...formValues,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      operation_summary: "airtime_purchase",
      service_code: "ZWSC01",
      phonenumber: data.phonenumber,
    };
    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(airtimePurchase(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={data.firstname}
                type="text"
                label="Name:"
                name="name"
                disabled
              />

              <FormikControl
                control="input"
                value={data.email}
                type="email"
                label="Email:"
                name="email"
                disabled
              />

              <FormikControl
                control="input"
                value={data.phonenumber}
                type="tel"
                label="Phone number:"
                name="phone_number"
                disabled
              />

              <FormikControl
                control="input"
                value={values.altphonenumber}
                type="tel"
                label="Alternate phone number:"
                name="alt_phone_number"
              />

              <FormikControl
                control="input"
                value={values.dateofbirth}
                type="date"
                label="Date of birth:"
                name="date_of_birth"
              />

              <FormikControl
                control="input"
                value={values.address}
                type="text"
                label="Address:"
                name="address"
              />

              <FormikControl
                control="input"
                value={data.email}
                type="email"
                label="Select state:"
                name="state"
              />

              <FormikControl
                control="input"
                value={data.email}
                type="select"
                label="Select LGA:"
                name="beneficiary_phonenumber"
              />

              <FormikControl
                control="input"
                type="integer"
                value={values.amount}
                label="Amount:"
                name="amount"
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" onClick={() => setValue(true)} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SoftPOSForm;
