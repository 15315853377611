import { Formik, Form } from "formik";
import * as Yup from "yup";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import Back from "../Back";
import Constant from "../Constant";
import { Link, useNavigate } from "react-router-dom";
import { useQueryParams } from "../../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransactionFlow,
  fetchTransactionList,
  fetchUserBalance,
  login,
} from "../../data/local/reducers/user.reducer";
import React from "react";
import Loading from "../SelectValue/Loading";

const validationSchema = Yup.object().shape({
  phonenumber: Yup.string().matches(
    Constant.phoneRegExp,
    "Phone number is not valid"
  ),
  pin: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, "Must be exactly 4 digits")
    .max(4, "Must be exactly 4 digits"),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const next = useQueryParams().get("next");
  const users = useSelector((state) => state.user);

  const handleSubmit = async (values) => {
    const { pin, phonenumber } = values;
    let userCredential = {
      phonenumber,
      pin,
      transaction_id: generateTransactionId(),
      channel: "web",
    };
    const { payload } = await dispatch(login(userCredential));

    if (payload.status_code === "0") {
      await dispatch(
        fetchTransactionList({
          phonenumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: payload.token,
        })
      );
      await dispatch(
        fetchTransactionFlow({
          phonenumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: payload.token,
        })
      );
      await dispatch(
        fetchUserBalance({
          phonenumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: payload.token,
        })
      );
      navigate("/dashboard");
    }
  };

  return (
    <div className="mx-4 md:mx-16 h-screen grid content-center text-sm">
      <Loading open={users.loading} />
      <div className="bg-white rounded-md px-8 md:px-10 py-16">
        <div className="flex justify-between items-center">
          <Back />
          <Link to="/register">
            <p className="text-button-color font-bold">Register</p>
          </Link>
        </div>

        <div className="pt-8">
          <p className="text-pri-text font-bold">Sign in</p>
        </div>
        <div className="py-8">
          <Formik
            initialValues={{
              phonenumber: "",
              pin: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, errors, handleChange, touched }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="tel"
                  value={values.phonenumber}
                  onChange={handleChange}
                  label="Phone number"
                  name="phonenumber"
                  error={touched.phonenumber && errors.phonenumber}
                />
                <FormikControl
                  control="input"
                  type="password"
                  value={values.pin}
                  onChange={handleChange}
                  label="Pin"
                  name="pin"
                  error={touched.pin && errors.pin}
                />
                <div className="grid grid-cols-1 mt-6"></div>
                <Button name={"Login"}></Button>
              </Form>
            )}
          </Formik>
        </div>

        <div>
          <p className="text-button-color">Forgot PIN?</p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
