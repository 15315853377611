import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { referralList } from "../../data/local/reducers/miscellaneous.reducer";
import "../../index.css";

export function Chart() {
  const [receiptData, setReceiptData] = useState([]);
  const dispatch = useDispatch();
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(
        referralList({ phonenumber: phoneNumber })
      );

      setReceiptData(payload?.result || []);
    };
    fetchData();
  }, [dispatch, phoneNumber]);

  const countValues =
    receiptData?.map((item) => parseInt(item.count_value, 10)) ?? [];
  const serviceAccountNames =
    receiptData?.map((item) => item.service_account_name) ?? [];

  const config = {
    series: [
      {
        data: countValues,
        //colors: colors // Assign colors to bars
      },
    ],
    options: {
      // chart: {
      //   type: "bar",
      //   height: 350, // Adjust height as needed
      // },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          borderRadius: 8,
          borderRadiusApplication: "end",
          startingShape: "flat",
          endingShape: "rounded",
          dataLabels: {
            position: "bottom",
          },
        },
      },

      xaxis: {
        categories: serviceAccountNames,
        labels: {
          maxHeight: 170,
          style: {
            hideOverlappingLabels: true,
            trim: true,
            colors: [],
            fontSize: "6px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          }, // Rotate labels for better readability if needed
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
      },
      legend: {
        show: false,
        position: "bottom",
        fontSize: "10px",
      },
      chart: {
        height: "400",
      },
    },
  };

  return (
    <div className="rounded-[10px] border-gray-200 border-2 mb-4 pb-4 ">
      <div className="px-2 py-4 border-b-2 border-gray-200 flex justify-between">
        <h1>Services Chart</h1>
      </div>
      <div id="chart-container">
        <ReactApexChart
          options={config.options}
          series={config.series}
          type="bar"
        />
      </div>
    </div>
  );
}
