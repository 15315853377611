import React from "react";
import Back from "../../Components/Back";
import StoreAd from "../../Components/storeAdvert";
import WaecTransactionTable from "../../Components/Exams/waecTransaction";

function WaecHistory() {
  return (
    <div>
      <div className="flex items-center gap-4 pb-4 text-sm font-bold border-b">
        <Back />
        <span>Transactions</span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-8">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5"> <WaecTransactionTable /> </div>
        </div>
        <div className="grid grid-cols-1 gap-5">
          <StoreAd />
        </div>
      </div>
    </div>
  );
}

export default WaecHistory;
