import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { useSelector, useDispatch } from "react-redux";
import {
  userDetails,
  walletBeneficiary,
  walletTransfer,
  saveWalletBeneficiaries,
} from "../../data/local/reducers/miscellaneous.reducer";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";

function WalletTransferForm() {
  const [value, setValue] = useState(false);
  const [wallet, setWallet] = useState("");
  const handleClose = () => setValue(false);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const [recipientName, setRecipientName] = useState("");
  const [formValues, setFormValues] = useState(null);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [savedbeneficiary, setSavedBeneficiary] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
    if (showDropdown) {
      setSelectedBeneficiary(""); // Reset selected beneficiary when hiding the dropdown
    }
    if (!showDropdown) {
      walletBeneficiaries();
    }
  };

  const walletBeneficiaries = async () => {
    const { payload } = await dispatch(
      walletBeneficiary({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    const { result } = payload;
    setBeneficiary(result);
  };

  const walletBenefit = beneficiary ? [...beneficiary] : [];

  const handleWalletChange = async (event) => {
    const walletPhoneNumber = event.target.value;
    setWallet(walletPhoneNumber);
    if (walletPhoneNumber.length === 11) {
      const { payload } = await dispatch(
        userDetails({
          phonenumber: walletPhoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      const { names } = payload;
      setRecipientName(names);
    }
  };

  const saveWalletBeneficiary = async () => {
    const { payload } = await dispatch(
      saveWalletBeneficiaries({
        phonenumber: phoneNumber,
        beneficiary_names: recipientName,
        beneficiary_customer_id: wallet,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    const { names } = payload;
    setRecipientName(names);
  };

  const initialValues = {
    sender_id: phoneNumber,
    amount: "",
    description: "",
  };

  const validationSchema = Yup.object({
    sender_id: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values);
    setValue(true);
    resetForm();
  };

  const onSubmit = async (values, { resetForm }) => {
    if (savedbeneficiary) {
      saveWalletBeneficiary();
    }
    setFormValues(values);
    setValue(true);
    const formData = {
      ...formValues,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      receiver_id: showDropdown ? selectedBeneficiary.split("|")[1] : wallet,
      channel: "web",
      user_type: data.user_type,
      token: token,
      operation_summary: "wallet transfer",
      sender_id: data.phonenumber,
    };

    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(walletTransfer(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
      <div className="flex justify-end mr-4">
        <button
          type="button"
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 font-semibold px-3 py-2 text-sm rounded shadow"
          onClick={handleDropdownToggle}
        >
          Show Beneficiaries
        </button>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {showDropdown && (
                <FormikControl
                  control="selectBeneficiary"
                  options={walletBenefit}
                  label="Select wallet beneficiary:"
                  name="bank_code"
                  value={values.walletBeneficiaries}
                  onChange={(event) => {
                    setSelectedBeneficiary(event.target.value); // Update selected beneficiary value
                  }}
                  error={touched.bank_code && errors.bank_code}
                />
              )}
              <FormikControl
                control="input"
                value={values.sender_id}
                type="integer"
                label="Recipient wallet number:"
                name="sender_id"
                disabled={true}
              />

              <FormikControl
                control="input"
                value={
                  showDropdown
                    ? selectedBeneficiary.split("|")[1]
                    : values.accountNo
                }
                type="text"
                label="Recipient Wallet Account No:"
                name="account_no"
                onChange={handleWalletChange}
              />

              <FormikControl
                control="input"
                type="text"
                label="Recipient name:"
                name="recipient"
                value={
                  showDropdown
                    ? selectedBeneficiary.split("|")[0]
                    : recipientName
                }
              />

              <FormikControl
                control="input"
                value={values.amount}
                type="integer"
                label="Amount:"
                name="amount"
                error={touched.phoneNumber && errors.phoneNumber}
              />

              <FormikControl
                control="input"
                value={values.description}
                type="text"
                label="Description:"
                name="description"
                error={touched.phoneNumber && errors.phoneNumber}
              />
            </div>

            {!showDropdown && (
              <div className="px-4 text-sm items-center text-slate-600 mb-10 flex gap-3">
                <input
                  type="checkbox"
                  name="Save beneficiary"
                  id="save_beneficiary"
                  checked={values.send_message}
                  onChange={() => setSavedBeneficiary(!savedbeneficiary)}
                />
                <span>Save as beneficiary</span>
              </div>
            )}
            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default WalletTransferForm;
