import React from "react";
import LoginForm from "../../Components/Login/LoginForm";
import Banneroutside from "../../Components/outsidebanner";

function Login() {
  return (
    //     <div className=' bg-bg-main h-screen'>
    //        <div className=' grid md:grid-cols-2 gap-4 p-4'>
    //         <div>
    //           <LoginForm/>
    //         </div>
    //         <div className=' hidden md:block signin'>
    //           <div className=' '>
    //           <div className=' '>
    //           <span className='  absolute bottom-28 left-1/2 transform -translate-x-1/2 -translate-y-1/2  p-3 rounded-xl bg-trans-color  text-center text-white '> Photo credit: Seyi Owolabi</span>
    //           <p className=' absolute bottom-16 left-32 text-center text-white'>Make your DSTV, GoTV and Startimes subscriptions on zippyworld <br></br>
    // to enjoy the 2022 FIFA WORLD CUP</p>
    //           </div>

    //           </div>
    //         </div>
    //        </div>
    //     </div>
    <div className="bg-bg-main h-screen">
      <div className="grid lg:grid-cols-2">
        <div>
          <LoginForm />
        </div>
        <Banneroutside />
      </div>
    </div>
  );
}

export default Login;
