import React from "react";
import logo from "../../images/zippylogo.png";
import textLogo from "../../images/ZippyworldLogoText.svg"
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <div className="w-full sticky top-0 left-0 p-0 m-0">
      <div className="flex items-center justify-between backdrop-blur-md bg-white/30 p-4 lg:px-16 border-b">
        <div className="flex gap-5 items-center py-3 md:py-0">
          <div className="hidden md:block">
            <img src={logo} alt="" className="w-10" />
          </div>
          <div>
            <img src={textLogo} alt="" className="h-5 md:h-6"/>
          </div>
        </div>
        <Link to ="/login">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M4 15H6V20H18V4H6V9H4V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V15ZM10 11V8L15 12L10 16V13H2V11H10Z" fill="rgba(18,126,200,1)"></path></svg>
        </Link>
      </div>
    </div>
  );
};

export default Nav;
