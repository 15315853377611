import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Button from '../SelectValue/Button'
import FormikControl from '../SelectValue/FormikControl'
import SecurityAnswerModal from '../SelectValue/securityAnswerModal'

function GotvForm() {

  const [value, setValue] = useState(false)
  const handleClose = () => setValue(false);

  const products = [
    { key: 'Select an option', value: '' },
    { key: 'Male', value: 'male' },
    { key: 'Female', value: 'female' }
  ]
  
  const initialValues = { 
    smartcard: '',
    customer: '.',
    customernumber: '.',
    product: '',
    packagename: '.',
    productcode: '',
    duration: '',
    amount: '.',
  }

  const validationSchema = Yup.object({
    smartcard:Yup.string().required('Required'),
    customer:Yup.string().required('Required'),
    customernumber: Yup.string().required('Required'),
    product: Yup.string().required('Required'),
    productcode: Yup.string().required('Required'),
    packagename: Yup.string().required('Required'),
    duration: Yup.string().required('Required'),
    amount: Yup.string().required('Required'),
   })

  const onSubmit = values => {
   setValue(true)
  }

  return (
    <div>

     <SecurityAnswerModal
      open = {value}
      handleClose = {handleClose}
      />

    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
        {formik => (
        <Form>
            <div className='p-4 grid grid-cols-1 md:grid-cols-2 gap-4'> 
              <FormikControl
                control='input'
                type='integer'
                label='Smartcard number:'
                name='smartcard'
              />

              <FormikControl
                control='input'
                type='text'
                label='Customer name:'
                name='customer'
                disabled
              />

              <FormikControl
                control='input'
                type='integer'
                label='Customer number:'
                name='customernumber'
                disabled
              />

              <FormikControl
                control='select'
                options={products}
                label='Select product:'
                name='product'
              /> 

              <FormikControl
                control='input'
                type='text'
                label='Package name:'
                name='packagename'
                disabled
              />

              <FormikControl
                control='input'
                type='text'
                label='Product code:'
                name='productcode'
              />

              <FormikControl
                control='input'
                type='integer'
                label='Subscription period:'
                name='duration'
              />

              <FormikControl
                control='input'
                type='integer'
                label='Subscription amount:'
                name='amount'
                disabled
              /> 

              </div>

                
              <div className='px-4 text-sm items-center text-slate-600 mb-5 flex gap-3'>
                <input type="checkbox" name="Save beneficiary" id="save_beneficiary" />
                <span>Save as beneficiary</span>
              </div>
              
              <div className='px-4 grid grid-cols-1 md:grid-cols-2'>
                <Button
                  name='Continue'
                />
              </div>
              
        </Form>
        )}
    </Formik>
    </div>
  )
}

export default GotvForm