import React, { useState } from "react";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import SavedBeneficiaryButton from "../../Components/savedBeneficiaryButton";
import DstvBeneficiaryModal from "../../Components/Beneficiary by service/dstvBeneficiaries";
import { useLocation } from "react-router-dom";
import DstvPlatinumForm from "../../Components/TvSubscription/dstvPlatinum";
import DownloadTemplate from "../../Components/DownloadTemplate";

function DstvPlatinums(props) {
  const [value, setValue] = useState(false);
  const closeDstvList = () => setValue(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serviceCode = searchParams.get("SERVICE_CODE");
  const operatorCode = searchParams.get("OPERATOR_CODE");
  const showDstvList = (values) => {
    setValue(true);
  };

  return (
    <div>
      <DstvBeneficiaryModal
        launchDstvList={value}
        closeDstvList={closeDstvList}
      />

      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        <span>{serviceCode.toUpperCase()} Diamond</span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <div className="rounded-lg border text-sm">
<DownloadTemplate/>
              <div className="py-4 grid grid-cols-1">
                <DstvPlatinumForm
                  serviceCode={serviceCode}
                  operatorCode={operatorCode}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-5">
          <StoreAd />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default DstvPlatinums;
