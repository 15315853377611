import React from 'react'
import NavCard from './navCard'
import diamond from '../images/Zippydiamond.png'
import platinum from '../images/Zippyplatinum.png'
import Back from './Back'
import RecentTransactions from './recentTransactions'
import StoreAd from './storeAdvert'
import { useNavigate } from 'react-router-dom';

function Types({pageName,navigateDiamond,navigatePlatinum}) {
    const navigate = useNavigate();
  return (
    <div>      <div className='flex items-center text-sm font-bold gap-4 border-b pb-4'>
    <Back />
    {pageName}
  </div>
  <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5'>
     <div className='lg:col-span-2'>
       <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mb-5'>
         <div className='cursor-pointer' onClick={() => navigate(navigateDiamond)}>
         <NavCard  serviceImg = {diamond} serviceName = "Zippy Diamond" />
         </div>
         <div className='cursor-pointer' onClick={() => navigate(navigatePlatinum)}>
         <NavCard  serviceImg = {platinum} serviceName = "Zippy Platinum" />
         </div>
       </div> 
       <StoreAd />
     </div>
     <div>
      <RecentTransactions />
     </div>
  </div></div>
  )
}

export default Types;