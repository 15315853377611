import inflow from "../../images/inflow.svg";
import outflow from "../../images/outflow.svg";
import commission from "../../images/commission.svg";
import { useSelector } from "react-redux";
import { AmountFormatter, NairaSymbol } from "../../utils/constant";

const InflowOutflow = () => {
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const transactionFlowProfile = useSelector(
    (state) => state.user.fetchTransactionFlowProfile
  );
  const userCommission =
    loginProfile.customer_commission_wallet_balance.amount?.toLocaleString() ??
    "";
  const inflows =
    transactionFlowProfile["result"].inflow?.toLocaleString() ?? "";
  const outflows =
    transactionFlowProfile["result"].outflow?.toLocaleString() ?? "";
  return (
    <div className="flex justify-between lg:grid lg:content-between h-full py-0 md:py-4 px-4">
      <div className="grid lg:flex items-center gap-3 font-semibold">
        <img src={inflow} alt="" className="hidden lg:block" />
        <span className="grid text-[#22AD80] text-center lg:text-start">
          <span className="opacity-50 text-xs">Inflow:</span>
          <span>
            <NairaSymbol />
            {inflows}
          </span>
        </span>
      </div>

      <div className="grid lg:flex items-center gap-3 font-semibold">
        <img src={outflow} alt="" className="hidden lg:block" />
        <span className="grid text-[#F9533C] text-center lg:text-start">
          <span className="opacity-50 text-xs">Outflow:</span>
          <span>
            <NairaSymbol />
            {outflows}
          </span>
        </span>
      </div>

      <div className="grid lg:flex items-center gap-3 font-semibold">
        <img src={commission} alt="" className="hidden lg:block" />
        <span className="grid text-[#127EC8] text-center lg:text-start">
          <span className="opacity-50 text-xs">Commission:</span>
          <span>
            <AmountFormatter amount={userCommission} currency="NGN" />
          </span>
        </span>
      </div>
    </div>
  );
};

export default InflowOutflow;
