import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import {
  accountVerification,
  bankBeneficiary,
  bankTransfer,
  getBanks,
  saveBankBeneficiaries,
} from "../../data/local/reducers/miscellaneous.reducer";
import Loading from "../SelectValue/Loading";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { showSuccessToast } from "../../utils/api-utils";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import { ContactlessOutlined } from "@mui/icons-material";

function BankTransferForm() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(false);
  const [banks, setBanks] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [recipientName, setRecipientName] = useState("");
  const handleClose = () => setValue(false);
  const users = useSelector((state) => state.misc);
  const [formValues, setFormValues] = useState(null);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [savedbeneficiary, setSavedBeneficiary] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
    if (!showDropdown) {
      bankBeneficiaries();
    }
  };

  const bankBeneficiaries = async () => {
    const { payload } = await dispatch(
      bankBeneficiary({
        phonenumber: phoneNumber,
      })
    );
    const { result } = payload;

    setBeneficiary(result);
  };

  const walletBenefit = beneficiary ? [...beneficiary] : [];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to true before fetching banks
      const { payload } = await dispatch(getBanks());
      setBanks(payload);

      setIsLoading(false); // Set loading state to false after banks are fetched
    };
    fetchData();
  }, [dispatch]);

  const banklist = banks ? [{ key: "Select a bank", value: "" }, ...banks] : [];

  const initialValues = {
    account_number: "",
    amount: "",
    description: "",
    bank_code: "",
  };

  const verifyAccountNumber = async (accountNumber, bankCode) => {
    if (accountNumber.length === 10) {
      const { payload } = await dispatch(
        accountVerification({
          account_number: accountNumber,
          bank_code: bankCode.split("|")[0],
        })
      );
      const { result } = payload;

      setRecipientName(result);
    }
  };

  const validationSchema = Yup.object({
    amount: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    resetForm();
  };

  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values);
    const { account_number, bank_code } = formValues; // Store the form values in the state
    setValue(true);
    if (savedbeneficiary) {
      const { payload } = await dispatch(
        saveBankBeneficiaries({
          phonenumber: phoneNumber,
          beneficiary_name: recipientName,
          account_no: account_number,
          bank_code: bank_code.split("|")[0],
          bank_name: bank_code.split("|")[1],
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
    }

    const formData = {
      ...formValues,
      bank_code: showDropdown
        ? selectedBeneficiary.split("|")[1]
        : bank_code.split("|")[0],
      security_answer: values.securityanswer,
      account_number: showDropdown
        ? selectedBeneficiary.split("|")[3]
        : account_number,
      account_name: showDropdown
        ? selectedBeneficiary.split("|")[2]
        : recipientName,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      operation_summary: "Bank transfer",
      sender_phonenumber: data.phonenumber,
      phonenumber: data.phonenumber,
    };

    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(bankTransfer(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={isLoading} />
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
      <div className="flex justify-end mr-4">
        <button
          type="button"
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 font-semibold px-3 py-2 text-sm rounded shadow"
          onClick={handleDropdownToggle}
        >
          Show Beneficiaries
        </button>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {showDropdown && (
                <FormikControl
                  control="selectBankBeneficiary"
                  options={walletBenefit}
                  label="Select wallet beneficiary:"
                  name="bank_codes"
                  value={values.walletBeneficiaries}
                  onChange={(event) => {
                    setSelectedBeneficiary(event.target.value);
                  }}
                  error={touched.bank_code && errors.bank_code}
                />
              )}
              {!showDropdown && (
                <FormikControl
                  control="selectBank"
                  options={banklist}
                  label="Select bank:"
                  name="bank_code"
                  value={values.bank_codes}
                  onChange={(event) => {
                    handleChange(event);
                    verifyAccountNumber(
                      values.account_number,
                      event.target.value
                    );
                  }}
                />
              )}
              {showDropdown && (
                <FormikControl
                  control="input"
                  label="Select bank:"
                  name="bank_code"
                  value={selectedBeneficiary.split("|")[0]}
                />
              )}
              <FormikControl
                control="input"
                type="integer"
                label="Recipient account number:"
                name="account_number"
                value={
                  showDropdown
                    ? selectedBeneficiary.split("|")[3]
                    : values.account_number
                }
                onChange={(event) => {
                  handleChange(event);
                  verifyAccountNumber(event.target.value, values.bank_code);
                }}
                error={touched.account_number && errors.account_number}
              />

              <FormikControl
                control="input"
                type="text"
                label="Recipient name:"
                name="recipient"
                value={
                  showDropdown
                    ? selectedBeneficiary.split("|")[2]
                    : recipientName
                }
                onChange={handleChange}
                error={touched.recipient && errors.recipient}
              />

              <FormikControl
                control="input"
                type="integer"
                label="Amount:"
                name="amount"
                value={values.amount}
                onChange={handleChange}
                error={touched.amount && errors.amount}
              />

              <FormikControl
                control="input"
                type="text"
                label="Description:"
                name="description"
                value={values.description}
                onChange={handleChange}
                error={touched.description && errors.description}
              />
            </div>

            {!showDropdown && (
              <div className="px-4 text-sm items-center text-slate-600 mb-10 flex gap-3">
                <input
                  type="checkbox"
                  name="Save beneficiary"
                  id="save_beneficiary"
                  checked={values.send_message}
                  onChange={() => setSavedBeneficiary(!savedbeneficiary)}
                />
                <span>Save as beneficiary</span>
              </div>
            )}

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BankTransferForm;
