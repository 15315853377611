import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import Back from "../Back";
import { Link, useNavigate } from "react-router-dom";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../SelectValue/Loading";
import { sendOTP, verifyBVN } from "../../data/local/reducers/user.reducer";
import { showInfoToast } from "../../utils/api-utils";

const validationSchema = Yup.object({
  bvn: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(11, "Must be exactly 11 digits")
    .max(11, "Must be exactly 11 digits"),
});
function RegisterForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);

  const handleSubmit = async (values) => {
    const { bvn, phonenumber } = values;
    let userCredential = {
      bvn,
      phonenumber,
      transaction_id: generateTransactionId(),
      channel: "web",
    };
    const { payload } = await dispatch(verifyBVN(userCredential));
    if (payload.status_code === "0") {
      let phoneNumber = payload.result.personal_info["phone_number"];
      let email = payload.result.personal_info["email"] ?? "";
      let personalInfo = payload.result;
      let otpCredential = { phonenumber: phoneNumber, email: email };
      if(email !== null && email !== '' ){
      dispatch(sendOTP(otpCredential));
      navigate("/otp", { state: { phone: phoneNumber, info: personalInfo } });
      }else{
        showInfoToast("Please note that your email is not available on the identity number provided, You can contact our customer representatives at 09039855986 or send an email to zippyworld@macrotelgroup.com.")
      }
    }
  };

  return (
    <div className="mx-4 md:mx-16 h-screen grid content-center text-sm">
      <Loading open={users.loading} />
      <div className="bg-white rounded-md px-8 md:px-10 py-16">
        <div className="flex justify-between items-center">
          <Back />
          <Link to="/login">
            <p className=" text-button-color font-bold">Sign in</p>
          </Link>
        </div>

        <div className="pt-8">
          <p className="text-pri-text font-bold">Register with BVN</p>
          <p className="opacity-50">
            To check your BVN, dial *565*0# on the sim card you used for BVN
            enrollment.
          </p>
        </div>

        <div className="py-8">
          <Formik
            initialValues={{
              bvn: "",
              phonenumber: "07099884433",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, handleChange, touched }) => (
              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  values={values.bvn}
                  onChange={handleChange}
                  error={touched.bvn && errors.bvn}
                  label="BVN"
                  name="bvn"
                />
                <div className=" grid grid-cols-1 mt-6">
                  <Button name="Verify" />
                </div>
              </Form>
            )}
          </Formik>
        </div>

      </div>
    </div>
  );
}

export default RegisterForm;
