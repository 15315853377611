import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '../SelectValue/Button';
import FormikControl from '../SelectValue/FormikControl';

function SecurityAnswer({ onSubmit }) {
  const initialValues = {
    securityanswer: ''
  };

  const validationSchema = Yup.object({
    securityanswer: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, touched }) => (
        <Form>
          <div className='px-1 pt-5 grid grid-cols-1 gap-2 text-sm'>
            <FormikControl
              control='input'
              type='password'
              label='Security answer:'
              name='securityanswer'
              value={values.securityanswer}
            />

            <div className='grid grid-cols-2'>
              <Button name='Submit' type='submit' />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SecurityAnswer;