import React from "react";
import image from "../../images/Right Div Img.png";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";


function TopSec() {
  return (
    <div className="mesh w-full text-[#127EC8]">
      <Nav />
      <div className="grid grid-cols-1 lg:grid-cols-2 px-4 md:px-16 pt-5 pb-20 items-center gap-10 mb-10 mt-10 md:mt-0">
        <div className="order-last lg:order-first">
          <p className="text-xl lg:text-3xl font-bold mb-4 text-center lg:text-start">
            All Your Utility <br /> Payments In One Place
          </p>
          <p className="text-center lg:text-start mb-4 font-medium">
            Zippyworld allows you complete all your important utility payments
            in one place and at amazing prices
          </p>
          {/* <div className="flex items-center gap-6">
            <div className="mt-4">
              <Link
              to="/login"
              className="botin rounded hover:ring-[1px] ring-[#127EC8] ring-offset-1"
            >
              Sign in
            </Link>
            </div>
            <div className="flex gap-5 items-center justify-center lg:justify-start text-[#127EC8]">
              <Link
                to="/register"
                className="botin2"
              >
                <button className="flex gap-3 items-center justify-center lg:justify-start text-sm">
                  <span>Create a free account</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          </div> */}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Link to="/register">
              <div className="bg-[#127ec8] text-white px-4 py-6 md:py-4  rounded text-center text-sm">
                Create an account
              </div>
            </Link>
            <Link to="/login">
              <div className="p-4 text-center md:text-left hover:underline hover:underline-offset-1 rounded text-sm font-bold">
                Sign in
              </div>
            </Link>
          </div>
        </div>
        <div className="grid place-content-center p-0 md:p-10">
          <LazyLoadImage src={image} alt=""  />
        </div>
      </div>
    </div>
  );
}

export default TopSec;
