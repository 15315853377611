import React from "react"

function Services() {

    return (
   
        <div className='grid grid-cols-1 px-4 md:px-32 lg:px-56 py-10 justify-items-center text-[#127ec8]'>
          <div><span className='bg-[#127EC820] px-4 py-2 rounded-3xl'>🖐🏾</span></div>
          <p className="text-xl text-center font-bold mt-4">Your Finances In The Palm Of Your Hands</p>
          <span className="text-center font-medium mt-6">
          Sending money to loved ones or business partners should never be a daunting task.  From streamlining your bill payments to seamlessly sending money or indulging in guilt-free online shopping sprees, Zippyworld is your all-in-one solution.
          </span>
        </div>

    )
  }
      
export default Services