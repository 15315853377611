import React from "react";
import Input from "./InputField";
import PasswordField from "./PasswordField";
import Select from "./Select";
import Textarea from "./TextArea";
import SelectSecurityQuestion from "./SelectSecurityQuestion";
import SelectData from "./SelectData";
import SelectBank from "./SelectBank";
import SelectElectricity from "./selectElectricity";
import SelectFibre from "./selectFibre";
import SelectProduct from "./selectProduct";
import SelectStar from "./selectStar";
import SelectCable from "./selectCable";
import SelectBeneficiary from "./selectBeneficiaries";
import SelectBankBeneficiary from "./selectBankBeneficiary";
import SelectElectricBeneficiary from "./selectElectricityBeneficiary";
import SelectCableBeneficiary from "./selectCableBeneficiaries";
import SelectDate from "./dateTimeField";
import SelectExam from "./selectExam";
import SelectRemitaCategory from "./selectRemitaCategory";
import SelectBillerId from "./selectBillerId";
import SelectBillerCategory from "./selectBillerCategory";
import MultiSelect from "./multiSelect";

function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "selected":
      return <SelectSecurityQuestion {...rest} />;
    case "selectData":
      return <SelectData {...rest} />;
    case "selectBank":
      return <SelectBank {...rest} />;
    case "selectExam":
      return <SelectExam {...rest} />;
    case "selectElectricity":
      return <SelectElectricity {...rest} />;
    case "selectFibre":
      return <SelectFibre {...rest} />;
    case "selectProduct":
      return <SelectProduct {...rest} />;
    case "selectStar":
      return <SelectStar {...rest} />;
      case "selectDate":
        return <SelectDate {...rest} />;
    case "selectCable":
      return <SelectCable {...rest} />;
    case "selectBeneficiary":
      return <SelectBeneficiary {...rest} />;
    case "selectBankBeneficiary":
      return <SelectBankBeneficiary {...rest} />;
    case "selectElectricityBeneficiary":
      return <SelectElectricBeneficiary {...rest} />;
    case "selectCableBeneficiary":
      return <SelectCableBeneficiary {...rest} />;
    case "selectRemitaCategory":
      return <SelectRemitaCategory {...rest} />;
      case "selectBillerId":
      return <SelectBillerId {...rest} />;
      case "selectBillerCategory":
      return <SelectBillerCategory {...rest} />;
    case "password":
      return <PasswordField {...rest} />;
      case "multiSelect":
        return <MultiSelect {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
