import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { invoiceProfiling } from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";

function CreateProfile() {
  const [value, setValue] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);

  const handleClose = () => setValue(false);

  const initialValues = {
    company: "",
    address: "",
    email: "",
    phonenumber: "",
  };

  const validationSchema = Yup.object({
    company: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    phonenumber: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setValue(true);
    const formData = {
      ...values,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      customer_id: data.phonenumber,
    };
    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(invoiceProfiling(formData))
        : "";

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.company}
                type="text"
                label="Company:"
                name="company"
              />
              <FormikControl
                control="input"
                value={values.email}
                type="text"
                label="Email:"
                name="email"
              />
              <FormikControl
                control="input"
                value={values.phonenumber}
                type="text"
                label="Phone Number:"
                name="phonenumber"
              />

              <FormikControl
                control="input"
                type="text"
                value={values.address}
                label="Address:"
                name="address"
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" onClick={() => setValue(true)} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateProfile;
