import React from "react";
import Back from "../../Components/Back";
import WalletDetails from "../../Components/EditProfile/walletDetails";
import StoreAd from "../../Components/storeAdvert";
import { useSelector } from "react-redux";

function AccountDetails() {
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const firstName = loginProfile.user_detail[0].firstname;
  const lastName = loginProfile.user_detail[0].lastname;
  const email = loginProfile.user_detail[0].email;
  const kycLevel = loginProfile.user_detail[0].kyc_level;

  return (
    <div>
      <div className="flex items-center gap-4 pb-4 text-sm font-bold border-b">
        <Back />
        <span>Account details</span>
      </div>

      <div className="flex gap-4 items-center mt-8">
        <span className="w-12 h-12 grid place-content-center bg-[#127ec8] text-white rounded-full">
          <p>
            {firstName.charAt(0)}
            {lastName.charAt(0)}
          </p>
        </span>
        <div>
          <p className="text-[15px] font-semibold">
            {firstName} {lastName}
          </p>
          <p className="text-[14px] font-normal opacity-75 ">{email}</p>
          <p className="text-[13px] font-normal opacity-50">
            KYC Level {kycLevel}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-8">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <WalletDetails />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5">
          <StoreAd />
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
