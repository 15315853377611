import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import Loading from "./Components/SelectValue/Loading";
import Error404 from "./Components/Error404";
import usePageTracking from "./usePageTracking";
import ReactGA from "react-ga4";

import Dashboard from "./Pages/Dashboard";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import ProtectedRoute from "./Pages/Protected/ProtectedRoute";
import Signup from "./Pages/SIgnup";
import Otp from "./Pages/SIgnup/Otp";
import Registration from "./Pages/SIgnup/Registration";
import SignUpNoBvn from "./Pages/SignUpNoBvn";
import OtpNoBvn from "./Pages/SignUpNoBvn/Otp";
import RegistrationNoBvn from "./Pages/SignUpNoBvn/Registration";
import LogoutTimer from "./Components/LogOut";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsCondition from "./Pages/TermsCondition";

const TRACKING_ID = process.env.REACT_APP_ANALYTICS;

ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Custom Title" });
  }, []);
    

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading open={true} />}>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/registerNoBvn" element={<SignUpNoBvn />} />
          <Route path="/otpNoBvn" element={<OtpNoBvn />} />
          <Route path="/privacy" element={<PrivacyPolicy/>} />
          <Route path="/t&c" element={<TermsCondition/>} />
          <Route path="/registrationNoBvn" element={<RegistrationNoBvn />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
