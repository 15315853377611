import React from 'react';
import { useField, useFormikContext } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import TextError from './TextError';

const CustomSelect = ({ label, name, options, ...rest }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (selectedList) => {
    setFieldValue(name, selectedList ? selectedList.map(option => option.value) : []);
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const mappedOptions = options ? options.map(option => ({ value: option.VALUE, name: option.DISPLAY_NAME })) : [];
  const selectedValues = field.value ? field.value.map(val => {
    const option = options.find(opt => opt.VALUE === val);
    return option ? { value: option.VALUE, name: option.DISPLAY_NAME } : null;
  }).filter(opt => opt !== null) : [];


  return (
    <div className="mb-3">
      <label className="text-pri-text block" htmlFor={name}>{label}</label>
      <Multiselect
        options={mappedOptions}
        selectedValues={selectedValues}
        onSelect={handleChange}
        onRemove={handleChange}
        displayValue="name"
        placeholder="Select options"
        onBlur={handleBlur}
        className="mb-1 w-full border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 p-3"
        {...rest}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-600 text-sm mt-1"><TextError>{meta.error}</TextError></div>
      ) : null}
    </div>
  );
};

export default CustomSelect;
