import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { AmountFormatter } from "../../utils/constant";

function SelectStar(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className=" mb-3 ">
      <label className=" text-pri-text block" htmlFor={name}>
        {label}
      </label>
      <Field
        as="select"
        className="mb-1 w-full border rounded-md border-gray-200 p-3 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
        id={name}
        name={name}
        {...rest}
      >
        <option value="">Select Plan</option>
        {options.map((option) => {
          return (
            <option
              className="mb-8"
              key={option.id}
              value={
                option.price +
                "|" +
                option.duration +
                "|" +
                option.code +
                "|" +
                option.name
              }
            >
              {option.name + " " + option?.duration } <p>for</p>{" "}
              <AmountFormatter
                className="pb-6"
                amount={option.price ?? ""}
                currency="NGN"
              />
            </option>
          );
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default SelectStar;
